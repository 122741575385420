
    import {Component, Vue} from 'vue-property-decorator';

    @Component({
        filters: {},
        components: {},
    })
    export default class SimpleTitleSearchBar extends Vue {
        private value: string | null = null;

        private async onValueChange() {
            this.applySearch();
        }

        private clearSearch() {
            this.value = null;
        }

        private applySearch() {
            if (this.$route.matched.length > 0) {
                const components = this.$route.matched[this.$route.matched.length - 1].instances;
                const defaultComponent = components.default as any;

                if (typeof defaultComponent.__searchAction === 'function') {
                    defaultComponent.__searchAction(this.value);
                }
            }
        }

        private mounted() {
            this.$router.afterEach(() => {
                this.clearSearch();
            });

            if (this.$route.matched.length > 0) {
                const components = this.$route.matched[this.$route.matched.length - 1].instances;
                const defaultComponent = components.default as any;

                if (defaultComponent && defaultComponent.__searchClearFunction == null) {
                    defaultComponent.__searchClearFunction = () => {
                        this.clearSearch();
                    };
                }
            }
        }
    }

