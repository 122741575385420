
    import {GenericApi, IWrapperResponse} from '@/api/generic';
    import FileInput from '@/components/FileInput.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ShowsMessages} from '@/mixins/ShowsMessages';
    import {IAddress} from '@/models/Address';
    import {FileInputResource} from '@/models/Part';
    import {IPolePrice} from '@/models/PolePrice';
    import {IRole} from '@/models/Role';
    import {IUser} from '@/models/User';
    import {Pagination} from '@/utils/api-tools/pagination';
    import {hasRole} from '@/utils/auth';
    import {toCurrency} from '@/utils/filters';
    import {http, url} from '@/utils/http';
    import {uuid} from '@/utils/strings';
    import {email, integer, required} from '@/utils/validation';
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';

    @Component({
        filters: { toCurrency },
        components: {SimpleMessageWrapper, FileInput},
    })
    export default class UserEdit extends Mixins(ShowsMessages) {
        public loadingData: boolean = true;
        public prices: IPolePrice[] | null = null;
        public bin: string | null = null;
        public binModelObject: FileInputResource = {};
        public gltf: string | null = null;
        public gltfModelObject: FileInputResource = {};

        public get rules() {
            return {
                prices: {
                    size: [required, integer],
                    price: [required, integer],
                },
            };
        }

        /**
         * Implement saving files, etc etc etc.
         */

        public async loadData() {
            this.loadingData = true;
            try {
                const response = await http.get(url('/api/v1/admin/pole'));
                const poleConfig = response.data as IWrapperResponse<IPoleConfig>;
                if (poleConfig.success && poleConfig.data) {
                    this.gltf = poleConfig.data.gltf;
                    this.gltfModelObject = {url: this.gltf, file: undefined};
                    this.bin = poleConfig.data.bin;
                    this.binModelObject = {url: this.bin, file: undefined};
                    this.prices = (poleConfig.data.prices || []).map((t: IPolePrice) => {
                        t.uuid = uuid();
                        t.size = t.size / 10;
                        return t;
                    });
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            } finally {
                this.loadingData = false;
            }
        }

        public beforeMount() {
            return this.loadData();
        }

        public async save() {
            const result = (this.$refs.form as any).validate();
            if (this.prices != null && result) {
                this.loadingData = true;
                try {
                    const postData = {
                        prices: this.prices.map((t: IPolePrice) => {
                            const price = this.$_.omit(t, 'uuid');
                            price.size = price.size * 10;
                            return price;
                        }),
                    };

                    await http.post(url('/api/v1/admin/pole'), postData);
                    await this.uploadFiles();

                    this.showMessage('De instellingen zijn opgeslagen');

                } catch (e) {
                    this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                } finally {
                    this.loadingData = false;
                }
            }
        }

        public addPrice() {
            this.prices!.push({
                uuid: uuid(),
                id: undefined,
                price: 0,
                size: 0,
            });
        }

        private async uploadFiles() {
            if (this.gltfModelObject && this.gltfModelObject.file && this.binModelObject && this.binModelObject.file) {
                const formData = new FormData();
                formData.append('gltf', this.gltfModelObject.file, this.gltfModelObject.file.name);
                formData.append('bin', this.binModelObject.file, this.binModelObject.file.name);
                const response = await http.post(url(`/api/v1/admin/pole/gltf`), formData);
            }
        }
    }

    export interface IPoleConfig {
        gltf: string;
        bin: string;
        prices: IPolePrice[];
    }
