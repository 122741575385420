import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IDampeningFloor} from '@/models/DampeningFloor';
import {IDampeningFloorCategory} from '@/models/DampeningFloorCategory';
import {IPart} from '@/models/Part';
import {IPartCategory} from '@/models/PartCategory';
import {IAgeCategory, IDifficultyCategory, IHeight, IMaterial} from '@/models/PartProperties';
import {http, url} from '@/utils/http';

export class DampingFloorApi extends GenericApi<IDampeningFloor> {

    constructor() {
        super('/api/v1/admin/damping-floor');
    }

    public async getCategories() {
        const uri = new URL(url('/api/v1/damping-floor/category'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IDampeningFloorCategory[]>;
    }
}
