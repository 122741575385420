import {PlaygroundStatus} from '@/models/Playground';
import _ from 'lodash';
import dayjs from 'dayjs';
import {HazardType} from '@/models/Hazard';
import {ChecklistItemCategory} from '@/models/ChecklistItem';

export function toCurrency(value: any) {
    if (typeof value !== 'number') {
        return value;
    }
    const formatter = new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR',
    });
    return formatter.format(value).replace(/\D00$/, '');
}

export function timeAgo(value: any) {
    if (_.isNumber(value)) {
        return dayjs(value * 1000).fromNow();
    }
}

export function statusText(status: PlaygroundStatus) {
    switch (status) {
        case PlaygroundStatus.APPROVED:
            return 'Goedgekeurd';
        case PlaygroundStatus.CANCELED:
            return 'Geannuleerd';
        case PlaygroundStatus.CONCEPT:
            return 'Concept';
        case PlaygroundStatus.DELIVERED:
            return 'Opgeleverd';
        case PlaygroundStatus.NEW:
            return 'Wachtend op goedkeuring TnT';
        case PlaygroundStatus.ORDERED:
            return 'Besteld';
        case PlaygroundStatus.READY_FOR_ASSEMBLY:
            return 'Klaar voor assemblage';
        case PlaygroundStatus.WAITING_FOR_APPROVAL:
            return 'Wachtend op goedkeuring Klant';
    }
}

export function typeText(type: HazardType) {
    switch (type) {
        case HazardType.DANGER:
            return 'Gevaar';
        case HazardType.NORM:
            return 'Norm';
    }
}

export function categoryText(category: ChecklistItemCategory) {
    switch (category) {
        case ChecklistItemCategory.DELIVERY:
            return 'Ontvangst';
        case ChecklistItemCategory.DIY:
            return 'Zelf toevoegen';
        case ChecklistItemCategory.PLACEMENT:
            return 'Plaatsing';

    }
}

export function booleanText(value: boolean) {
    return value ? 'Ja' : 'Nee';
}
