import {IRole} from '@/models/Role';
import {IUser} from '@/models/User';
import {RootState} from '@/store';
import {http, url} from '@/utils/http';
import {Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators';

export interface AuthenticationState {
    authenticated: boolean;
}

export function hasRole(user: IUser, name: string): boolean {
    return user != null && user.roles != null && user.roles.find((t: IRole) => t.name === name) != null;
}
export function hasAnyRole(user: IUser, name: string[]): boolean {
    return user != null && user.roles != null && name.reduce((acc: boolean, curr: string) => {
        return acc || user.roles.find((t: IRole) => t.name === curr) != null;
    }, false);
}

@Module
export default class AuthenticationModule extends VuexModule<AuthenticationState, RootState> {
    public authenticated: boolean = false;
    public user: IUser|null = null;

    @Mutation
    public setAuthenticated(value: boolean) {
        this.authenticated = value;
    }

    @Mutation
    public setUser(user: IUser|null) {
        this.user = user;
    }

    @MutationAction({mutate: ['authenticated', 'user']})
    public async logout() {
        await http.get(url('logout'));
        return {authenticated: false, user: null};
    }
}


