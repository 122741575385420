import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IPart} from '@/models/Part';
import {IPartCategory} from '@/models/PartCategory';
import {IAgeCategory, IDifficultyCategory, IHeight, IMaterial} from '@/models/PartProperties';
import {http, url} from '@/utils/http';
import {IPartSpecSync, ISyncPartSpec} from '@/models/PartSpecSync';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';

export class PartApi extends GenericApi<IPart> {

    constructor() {
        super('/api/v1/admin/part');
    }

    public async getPartCategories() {
        const uri = new URL(url('/api/v1/part/part-category'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IPartCategory[]>;
    }

    public async getAgeCategories() {
        const uri = new URL(url('/api/v1/part/age-category'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IAgeCategory[]>;
    }

    public async getDifficultyCategories() {
        const uri = new URL(url('/api/v1/part/difficulty-category'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IDifficultyCategory[]>;
    }

    public async getHeights() {
        const uri = new URL(url('/api/v1/part/height'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IHeight[]>;
    }

    public async getMaterials() {
        const uri = new URL(url('/api/v1/part/material'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IMaterial[]>;
    }

    public async getSyncablePartSpecs() {
        const uri = new URL(url(`/api/v1/admin/part/part-spec-sync`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IPaginationResponse<IPartSpecSync>>;
    }

    public async getSyncInProgressCount() {
        const uri = new URL(url(`/api/v1/admin/part/sync-in-progress-count`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<number>;
    }

    public async getSyncablePartSpec(referenceId: number) {
        const uri = new URL(url(`/api/v1/admin/part/part-spec-sync/${referenceId}`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<ISyncPartSpec>;
    }

    public async syncParts() {
        const uri = new URL(url(`/api/v1/admin/part/sync`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<any>;
    }

    public async syncPart(partId: number) {
        const uri = new URL(url(`/api/v1/admin/part/${partId}/sync`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<any>;
    }

    public async syncPartSpec(partSpecId: number) {
        const uri = new URL(url(`/api/v1/admin/part/sync-part-spec/${partSpecId}`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<any>;
    }
}
