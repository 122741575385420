export interface IHazard {
    id?: number;
    name: string;
    type: HazardType;
    alwaysShow: boolean;
    defaultValue: boolean;
}

export enum HazardType {
    DANGER = 1,
    NORM = 2,
}
