
import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {IUser} from '@/models/User';
import {toCurrency} from '@/utils/filters';
import settings from '@/utils/settings';
import {max, min, regex, required} from '@/utils/validation';
import Vue from 'vue';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {State} from 'vuex-class';
import {IColor} from '@/models/Color';

@Component({
    filters: {toCurrency},
    components: {SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class ColorEdit extends Mixins(ShowsMessages) {
    public loadingData: boolean = true;
    public color: IColor | null = null;
    public hexColor: string | null = null;


    @Watch('hexColor')
    public onColorSelected() {
        if (this.hexColor && this.color) {
            console.log('this.hexColor')
            console.log(this.hexColor)
            this.color.value = this.hexColor.replace('#', '');
        }
    }

    @State((state: any) => state.authentication.user)
    public currentUser!: IUser;

    public get rules() {
        return {
            name: [required, max(255), min(3)],
            color: [required],
        };
    }

    public async loadData() {
        this.loadingData = true;
        try {
            if (this.isEditing) {
                const response = await this.$api.color.get(Number(this.$route.params.id));
                this.color = response.data;
            } else {
                this.color = {
                    name: '',
                    value: '000000',
                };
            }
            this.hexColor = '#' + this.color?.value;
        } catch (e) {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public get isEditing() {
        return this.$route.name === 'color-edit';
    }

    public beforeMount() {
        return this.loadData();
    }

    @Watch('$route.params.id')
    public routeChanged() {
        this.loadData();
    }

    public async save() {
        let validated = this.validate();
        validated = (this.$refs.form as any).validate() && validated;

        if (this.color != null && validated) {
            this.loadingData = true;
            try {
                const requestObject = this.$_.cloneDeep(this.color);

                if (!this.isEditing) {
                    const response = await this.$api.color.create(requestObject);

                    if (!settings.REDIRECT_AFTER_SAVE && response.data && response.data.id) {
                        this.$router.push({name: 'color-edit', params: {id: response.data.id.toString()}});
                    }
                } else {
                    const response = await this.$api.color.save(requestObject);
                }

                if (settings.REDIRECT_AFTER_SAVE) {
                    this.$router.push({name: 'color-themes'});
                } else {
                    this.showMessage('Het kleurenthema is opgeslagen');
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het opslaan van de data.');
            } finally {
                this.loadingData = false;
            }
        }
    }

    private validate() {
        const vues = this.findDescendants(this, (item: Vue) => {
            return (item.$options.name === 'FileInput' || item.$options.name === 'ImageInput');
        });


        let result = true;
        for (const vue of vues) {
            result = (vue as any).validate() && result;
        }
        return result;
    }

    private findDescendants(item: Vue, matcher: (item: Vue) => boolean): Vue[] {
        const result: Vue[] = [];
        for (const descendant of item.$children) {
            if (matcher(descendant)) {
                result.push(descendant);
            }
            result.push(...result, ...this.findDescendants(descendant, matcher));
        }
        return result;
    }
}
