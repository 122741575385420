import {IDampeningFloor} from '@/models/DampeningFloor';
import {Idable} from '@/models/Idable';
import {FileInputResource} from '@/models/Part';
import {IHeight} from '@/models/PartProperties';
import {IPartSpec} from '@/models/PartSpec';
import {IUser} from '@/models/User';

export enum PlaygroundStatus {
    CONCEPT = 1,
    NEW = 2,
    WAITING_FOR_APPROVAL = 3,
    APPROVED = 4,
    ORDERED = 5,
    READY_FOR_ASSEMBLY = 6,
    DELIVERED = 7,
    CANCELED = 8,
}

export interface IDampingFloorPlacement {
    dampingFloor: IDampeningFloor;
    dampingFloorArea: number;
}

export interface IPlaygroundPole {
    size: number;
    count: number;
}

export interface IPlaygroundStep {
    height: number;
    count: number;
}

export interface IPartColor {
    groupName: string;
    color: string;
}

export interface IPartInstance extends Idable {
    partSpec: IPartSpec;
    colors: IPartColor[];
    height: IHeight;
}

export interface IPlayground extends Idable {
    user: Idable;
    state: PlaygroundStatus;
    name: string;
    description?: string;
    location?: string;
    constructionPrice?: number;
    template: boolean;
    icon?: string;
    customIcon?: string;
    comment?: string;
    internalComment?: string;
    generatedConstructionManual?: string;
    image1?: string;
    generatedHazards?: string;
    generatedChecklist?: string;
    validated: boolean;

    floor?: IDampingFloorPlacement;
    poles?: IPlaygroundPole[];
    steps?: IPlaygroundStep[];
    parts?: IPartInstance[];

    assignedMechanics?: IUser[];
    assignedInspectors?: IUser[];

    // Local helpers
    customIconObject?: FileInputResource;

}
