import {RootState} from '@/store';
import {Module, Mutation, VuexModule} from 'vuex-module-decorators';

export interface RouteState {
    publicName: string;
}

@Module
export default class RoutingModule extends VuexModule<RouteState, RootState> {
    public publicName: string = '';

    @Mutation
    public setPublicName(value: string) {
        this.publicName = value;
    }
}
