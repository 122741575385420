
import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {toCurrency} from '@/utils/filters';
import {Component, Vue} from 'vue-property-decorator';
import ColorThemeList from '@/views/color-themes/ColorThemeList.vue';
import ColorList from '@/views/color-themes/ColorList.vue';

@Component({
    components: {ColorList, ColorThemeList, SimpleDeleteDialog, SimpleMessageWrapper},
    filters: {toCurrency},
})
export default class Colors extends Vue {
    public tab: string = 'algemeen';
}
