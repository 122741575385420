
    import {Component, Vue} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class App extends Vue {

        private mounted() {
            window.addEventListener('dragover', (e) => {
                e.preventDefault();
            }, false);
            window.addEventListener('drop', (e) => {
                e.preventDefault();
            }, false);
        }
    }
