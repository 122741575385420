
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {SearchAction} from '@/decorators/search-action';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {toCurrency} from '@/utils/filters';
    import {Component, Prop, Watch, Vue, Mixins} from 'vue-property-decorator';

    @Component({
        components: {SimpleDeleteDialog, SimpleMessageWrapper},
        filters: {toCurrency},
    })
    export default class DampingFloorList extends Mixins(ListsObjects) {
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Naam', value: 'name'},
            {text: 'Valhoogte', value: 'maxHeight'},
            {text: 'Dikte', value: 'thickness'},
            {text: 'Prijs', value: 'price'},
            {text: 'Categorie', value: 'category.id', sortable: false},
            {text: '', sortable: false},
        ];

        public get api() {
            return this.$api.dampingFloors;
        }

        @SearchAction()
        public applySearch(value: string) {
            if (value) {
                this.searchCriteria = [new SearchCriterion('*', value)];
            } else {
                this.searchCriteria = this.searchCriteria!.filter((t: SearchCriterion) => t.key !== '*');
            }
            this.resetPagination();
        }
    }
