import Vue from 'vue';
import _ from 'lodash';

Object.defineProperty(Vue.prototype, '$_', { value: _ });

declare module 'vue/types/options' {
    interface ComponentOptions<V extends Vue> {
        _?: typeof _;
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $_: typeof _;
    }
}
