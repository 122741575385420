
    import {GenericApi} from '@/api/generic';
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {statusText, timeAgo} from '@/utils/filters';
    import {Component, Mixins} from 'vue-property-decorator';
    import {url} from '@/utils/http';

    @Component({
        filters: {
            timeAgo,
            statusText,
        },
        components: {SimpleDeleteDialog, SimpleMessageWrapper},
    })
    export default class PlaygroundMechanicList extends Mixins(ListsObjects) {
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Naam', value: 'name'},
            {text: 'Gebruiker', value: 'user.id'},
            {text: 'Status', value: 'state'},
            {text: 'Sjabloon', value: 'template'},
            {text: 'Bewerkt op', value: 'updatedAt'},
            {text: '', sortable: false},
        ];

        public get api() {
            return new GenericApi('api/v1/admin/playground/mechanic');
        }

        public url(path: string) {
            return url(path);
        }
    }
