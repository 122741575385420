
    import FileInput from '@/components/FileInput.vue';
    import HeightPicker from '@/components/HeightPicker.vue';
    import ImageInput from '@/components/ImageInput.vue';
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ShowsMessages} from '@/mixins/ShowsMessages';
    import {IUser} from '@/models/User';
    import {toCurrency} from '@/utils/filters';
    import settings from '@/utils/settings';
    import {integer, max, required} from '@/utils/validation';
    import Vue from 'vue';
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';
    import {ChecklistItemCategory, IChecklistItem} from '@/models/ChecklistItem';

    @Component({
        filters: {toCurrency},
        components: {SimpleDeleteDialog, HeightPicker, FileInput, ImageInput, SimpleMessageWrapper},
    })
    export default class ChecklistItemEdit extends Mixins(ShowsMessages) {
        public loadingData: boolean = true;
        public checklistItem: IChecklistItem | null = null;

        public categories: Array<{ label: string, value: ChecklistItemCategory }> = [
            {label: 'Ontvangst', value: ChecklistItemCategory.DELIVERY},
            {label: 'Zelf toevoegen', value: ChecklistItemCategory.DIY},
            {label: 'Plaatsing', value: ChecklistItemCategory.PLACEMENT},
        ];

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        public get rules() {
            return {
                description: [required],
                action: [required, max(255)],
                priority: [required, integer],
                category: [required],
            };
        }

        public async loadData() {
            this.loadingData = true;
            try {
                if (this.isEditing) {
                    const response = await this.$api.checklistItems.get(Number(this.$route.params.id));
                    this.checklistItem = response.data;
                } else {
                    this.checklistItem = {
                        category: null as any,
                        description: '',
                        action: '',
                        priority: 0,
                    };
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            } finally {
                this.loadingData = false;
            }
        }

        public get isEditing() {
            return this.$route.name === 'checklistItemEdit';
        }

        public beforeMount() {
            return this.loadData();
        }

        @Watch('$route.params.id')
        public routeChanged() {
            this.loadData();
        }

        public async save() {
            let validated = this.validate();
            validated = (this.$refs.form as any).validate() && validated;

            if (this.checklistItem != null && validated) {
                this.loadingData = true;
                try {
                    const requestObject = this.$_.cloneDeep(this.checklistItem);

                    if (!this.isEditing) {
                        const response = await this.$api.checklistItems.create(requestObject);

                        if (!settings.REDIRECT_AFTER_SAVE && response.data && response.data.id) {
                            this.$router.push({name: 'checklistItemEdit', params: {id: response.data.id.toString()}});
                        }
                    } else {
                        const response = await this.$api.checklistItems.save(requestObject);
                    }

                    if (settings.REDIRECT_AFTER_SAVE) {
                        this.$router.push({name: 'checklistItems'});
                    } else {
                        this.showMessage('Het checklist item is opgeslagen');
                    }
                } catch (e) {
                    this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                } finally {
                    this.loadingData = false;
                }
            }
        }

        private validate() {
            const vues = this.findDescendants(this, (item: Vue) => {
                return (item.$options.name === 'FileInput' || item.$options.name === 'ImageInput');
            });


            let result = true;
            for (const vue of vues) {
                result = (vue as any).validate() && result;
            }
            return result;
        }

        private findDescendants(item: Vue, matcher: (item: Vue) => boolean): Vue[] {
            const result: Vue[] = [];
            for (const descendant of item.$children) {
                if (matcher(descendant)) {
                    result.push(descendant);
                }
                result.push(...result, ...this.findDescendants(descendant, matcher));
            }
            return result;
        }
    }
