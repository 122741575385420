import SimpleTitleSearchBar from '@/components/SimpleTitleSearchBar.vue';
import AppBase from '@/views/AppBase.vue';
import DampingFloorEdit from '@/views/damping-floors/DampingFloorEdit.vue';
import DampingFloorList from '@/views/damping-floors/DampingFloorList.vue';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/Login.vue';
import NotFound from '@/views/NotFound.vue';
import PartEdit from '@/views/parts/PartEdit.vue';
import PartList from '@/views/parts/PartList.vue';
import PlaygroundEdit from '@/views/playgrounds/PlaygroundEdit.vue';
import PlaygroundList from '@/views/playgrounds/PlaygroundList.vue';
import QuotationRequestList from '@/views/playgrounds/QuotationRequestList.vue';
import PoleEdit from '@/views/poles/PoleEdit.vue';
import UserEdit from '@/views/users/UserEdit.vue';
import UserList from '@/views/users/UserList.vue';
import Vue from 'vue';
import Router, {Route, RouteConfig} from 'vue-router';
import ColorThemeList from '@/views/color-themes/ColorThemeList.vue';
import ColorThemeEdit from '@/views/color-themes/ColorThemeEdit.vue';
import Colors from '@/views/color-themes/Colors.vue';
import ChecklistItemList from '@/views/checklist-items/ChecklistItemList.vue';
import ChecklistItemEdit from '@/views/checklist-items/ChecklistItemEdit.vue';
import HazardList from '@/views/hazards/HazardList.vue';
import HazardEdit from '@/views/hazards/HazardEdit.vue';
import ColorEdit from '@/views/color-themes/ColorEdit.vue';

Vue.use(Router);

const users: RouteConfig[] = [
    {
        path: '/users',
        name: 'users',
        components: {
            default: UserList,
            toolbar: SimpleTitleSearchBar,
        },
        meta: {publicName: 'Gebruikers', roles: ['admin', 'administration']},
    },
    {
        path: '/users/new',
        name: 'userCreate',
        component: UserEdit,
        meta: {publicName: 'Gebruiker aanmaken', roles: ['admin', 'administration']},
    },
    {
        path: '/users/:id',
        name: 'userEdit',
        component: UserEdit,
        meta: {publicName: 'Gebruiker bewerken', roles: ['admin', 'administration']},
    },
];

const parts: RouteConfig[] = [
    {
        path: '/parts',
        name: 'parts',
        components: {
            default: PartList,
            toolbar: SimpleTitleSearchBar,
        },
        meta: {publicName: 'Onderdelen', roles: ['admin']},
    },
    {
        path: '/parts/new',
        name: 'partCreate',
        component: PartEdit,
        meta: {publicName: 'Onderdeel aanmaken', roles: ['admin']},
    },
    {
        path: '/parts/:id',
        name: 'partEdit',
        component: PartEdit,
        meta: {publicName: 'Onderdeel bewerken', roles: ['admin']},
    },
];

const dampingFloors: RouteConfig[] = [
    {
        path: '/damping-floors',
        name: 'damping-floors',
        components: {
            default: DampingFloorList,
            toolbar: SimpleTitleSearchBar,
        },
        meta: {publicName: 'Valondergronden', roles: ['admin']},
    },
    {
        path: '/damping-floors/new',
        name: 'damping-floorsCreate',
        component: DampingFloorEdit,
        meta: {publicName: 'Valondergrond aanmaken', roles: ['admin']},
    },
    {
        path: '/damping-floors/:id',
        name: 'damping-floorsEdit',
        component: DampingFloorEdit,
        meta: {publicName: 'Valondergrond bewerken', roles: ['admin']},
    },
];

const colorThemes: RouteConfig[] = [
    {
        path: '/color-themes',
        name: 'color-themes',
        components: {
            default: Colors,
            toolbar: SimpleTitleSearchBar,
        },
        meta: {publicName: 'Kleurenthema\'s', roles: ['admin']},
    },
    {
        path: '/color-themes/new',
        name: 'color-themesCreate',
        component: ColorThemeEdit,
        meta: {publicName: 'Kleurenthema aanmaken', roles: ['admin']},
    },
    {
        path: '/color-themes/:id',
        name: 'color-themesEdit',
        component: ColorThemeEdit,
        meta: {publicName: 'Kleurenthema bewerken', roles: ['admin']},
    },
    {
        path: '/color-themes/new-color',
        name: 'color-create',
        component: ColorEdit,
        meta: {publicName: 'Kleur aanmaken', roles: ['admin']},
    },
    {
        path: '/color-themes/edit-color/:id',
        name: 'color-edit',
        component: ColorEdit,
        meta: {publicName: 'Kleur bewerken', roles: ['admin']},
    },
];

const playgrounds: RouteConfig[] = [
    {
        path: '/playgrounds',
        name: 'playgrounds',
        components: {
            default: PlaygroundList,
            toolbar: SimpleTitleSearchBar,
        },
        meta: {publicName: 'Sjablonen', roles: ['admin', 'administration']},
    },
    {
        path: '/quotations',
        name: 'quotations',
        components: {
            default: QuotationRequestList,
            toolbar: SimpleTitleSearchBar,
        },
        meta: {publicName: 'Ontworpen speeltoestellen', roles: ['admin', 'administration']},
    },
    {
        path: '/playgrounds/:id',
        name: 'playgroundsEdit',
        component: PlaygroundEdit,
        meta: {publicName: 'Sjabloon bewerken', roles: ['admin', 'administration', 'mechanic', 'inspector']},
    },
    {
        path: '/quotations/:id',
        name: 'quotationsEdit',
        component: PlaygroundEdit,
        meta: {publicName: 'Ontworpen speeltoestel bewerken',
            roles: ['admin', 'administration', 'mechanic', 'inspector']},
    },
];

const hazards: RouteConfig[] = [
    {
        path: '/hazards',
        name: 'hazards',
        components: {
            default: HazardList,
            toolbar: SimpleTitleSearchBar,
        },
        meta: {publicName: 'Gevaren en toegepaste normen', roles: ['admin']},
    },
    {
        path: '/hazards/new',
        name: 'hazardCreate',
        component: HazardEdit,
        meta: {publicName: 'Gevaar aanmaken', roles: ['admin']},
    },
    {
        path: '/hazards/:id',
        name: 'hazardEdit',
        component: HazardEdit,
        meta: {publicName: 'Gevaar bewerken', roles: ['admin']},
    },
];

const checklistItems: RouteConfig[] = [
    {
        path: '/checklistItems',
        name: 'checklistItems',
        components: {
            default: ChecklistItemList,
            toolbar: SimpleTitleSearchBar,
        },
        meta: {publicName: 'Checklist items', roles: ['admin']},
    },
    {
        path: '/checklistItems/new',
        name: 'checklistItemCreate',
        component: ChecklistItemEdit,
        meta: {publicName: 'Checklist item aanmaken', roles: ['admin']},
    },
    {
        path: '/checklistItems/:id',
        name: 'checklistItemEdit',
        component: ChecklistItemEdit,
        meta: {publicName: 'Checklist item bewerken', roles: ['admin']},
    },
];

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: AppBase,
            meta: {requiresAuth: true},
            children: [
                {
                    path: '/',
                    redirect: 'dash',
                },
                {
                    path: '/dash',
                    name: 'dashboard',
                    component: Dashboard,
                },
                {
                    path: '/pole-config',
                    name: 'pole-config',
                    component: PoleEdit,
                    meta: {
                        publicName: 'Paalinstellingen',
                        roles: ['admin'],
                    },
                },
                ...users,
                ...parts,
                ...dampingFloors,
                ...playgrounds,
                ...colorThemes,
                ...hazards,
                ...checklistItems,
            ],
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/**',
            name: 'catchAll',
            component: NotFound,
        },
    ],
});
