
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {SearchAction} from '@/decorators/search-action';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {booleanText, typeText} from '@/utils/filters';
    import {Component, Mixins} from 'vue-property-decorator';
    import {hasRole} from '@/utils/auth';
    import {State} from 'vuex-class';
    import {IUser} from '@/models/User';

    @Component({
        components: {SimpleDeleteDialog, SimpleMessageWrapper},
        filters: {typeText, booleanText},
    })
    export default class HazardList extends Mixins(ListsObjects) {
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Naam', value: 'name'},
            {text: 'Type', value: 'type'},
            {text: 'Altijd tonen', value: 'always_show'},
            {text: 'Standaard waarde', value: 'default_value'},
            {text: '', sortable: false},
        ];

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        public get api() {
            return this.$api.hazards;
        }

        public get isAdmin() {
            return hasRole(this.currentUser, 'admin');
        }

        @SearchAction()
        public applySearch(value: string) {
            if (value) {
                this.searchCriteria = [new SearchCriterion('*', value)];
            } else {
                this.searchCriteria = this.searchCriteria!.filter((t: SearchCriterion) => t.key !== '*');
            }
            this.resetPagination();
        }
    }
