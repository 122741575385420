export interface IChecklistItem {
    id?: number;
    description: string;
    action: string;
    category: ChecklistItemCategory;
    priority: number;
}

export enum ChecklistItemCategory {
    DELIVERY = 1,
    DIY = 2,
    PLACEMENT = 3,
}
