
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {SearchAction} from '@/decorators/search-action';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {categoryText} from '@/utils/filters';
    import {Component, Mixins} from 'vue-property-decorator';
    import {hasRole} from '@/utils/auth';
    import {State} from 'vuex-class';
    import {IUser} from '@/models/User';

    @Component({
        components: {SimpleDeleteDialog, SimpleMessageWrapper},
        filters: {categoryText},
    })
    export default class ChecklistItemList extends Mixins(ListsObjects) {
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Omschrijving', value: 'description'},
            {text: 'Actie', value: 'action'},
            {text: 'Prioriteit', value: 'priority'},
            {text: 'Categorie', value: 'category'},
            {text: '', sortable: false},
        ];

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        public get api() {
            return this.$api.checklistItems;
        }

        public get isAdmin() {
            return hasRole(this.currentUser, 'admin');
        }

        @SearchAction()
        public applySearch(value: string) {
            if (value) {
                this.searchCriteria = [new SearchCriterion('*', value)];
            } else {
                this.searchCriteria = this.searchCriteria!.filter((t: SearchCriterion) => t.key !== '*');
            }
            this.resetPagination();
        }
    }
