
import LatestPlaygrounds from '@/components/LatestPlaygrounds.vue';
import LatestUsers from '@/components/LatestUsers.vue';
import PlaygroundMechanicList from '@/components/PlaygroundMechanicList.vue';
import {IUser} from '@/models/User';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import {hasRole, hasAnyRole} from '@/utils/auth';
import {State} from 'vuex-class';
import PlaygroundInspectorList from '@/components/PlaygroundInspectorList.vue';

@Component({
  components: {PlaygroundInspectorList, PlaygroundMechanicList, LatestPlaygrounds, LatestUsers},
})
export default class Dashboard extends Vue {

    @State((state: any) => state.authentication.user)
    public currentUser!: IUser;

    public get isAdminOrAdministration() {
        return hasAnyRole(this.currentUser, ['admin', 'administration']);
    }

    public get isMechanic() {
        return hasAnyRole(this.currentUser, ['mechanic']);
    }

    public get isInspector() {
        return hasAnyRole(this.currentUser, ['inspector']);
    }
}
