import _ from 'lodash';

export function email(value: string) {
    return !/^[^@]+@[^@]+\.[^@]+$/.test(value) ? 'E-mailadres is ongeldig' : true;
}

export function min(size: number) {
    return (value: string) => (value && value.length < size) ?
        `Dit veld moet minimaal ${size} tekens lang zijn` : true;
}

export function max(size: number) {
    return (value: string) => (value && value.length > size) ?
        `Dit veld mag maximaal ${size} tekens lang zijn` : true;
}

export function required(value: string) {
    let empty = false;
    if (value == null) {
        empty = true;
    } else if (_.isNumber(value)) {
        // don't do anything
    } else if (_.isEmpty(value)) {
        empty = true;
    }
    return empty ? 'Dit veld is verplicht' : true;
}

export function integer(val: string) {
    return (val === '' || val == null || /^[0-9]+$/.test(val)) ? true : 'Dit moet een geheel getal zijn.';
}

export function number(val: string) {
    return (val === '' || val == null || /^[0-9]+(\.[0-9]+)?$/.test(val)) ? true : 'Dit moet een getal zijn.';
}

export function regex(regexp: RegExp) {
    return (val: string) => regexp.test(val) ? true :
        'Dit veld moet aan het volgende patroon voldoen: ' + regexp + '.';
}
