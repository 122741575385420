import {Idable} from '@/models/Idable';
import {IPartCategory} from '@/models/PartCategory';
import {IAgeCategory, IDifficultyCategory, IMaterial} from '@/models/PartProperties';
import {IPartSpec} from '@/models/PartSpec';
import {IHazard} from '@/models/Hazard';
import {IChecklistItem} from '@/models/ChecklistItem';

export interface FileInputResource {
    file?: File;
    url?: string;
    directUrl?: boolean;
}

export interface IPartDefaultColors {
    part?: Idable;
    groupName: string;
    color: string;

    // Local helper
    uuid?: string;
}

export enum PartSizeCategory {
    SMALL = 1,
    MEDIUM = 2,
    LARGE = 3,
}

export interface IPart extends Idable {
    parent: null;
    category: IPartCategory;
    productCode: string;
    name: string;
    description: string;
    popup: string;
    icon: string;
    sizeCategory: PartSizeCategory;
    disabled: boolean;
    published: boolean;
    partSpecs: IPartSpec[];
    ageCategories: IAgeCategory[];
    difficultyCategories: IDifficultyCategory[];
    hazards: IHazard[];
    checklistItems: IChecklistItem[];
    materials: IMaterial[];
    partDefaultColors: IPartDefaultColors[];

    // Local helper
    iconObject?: FileInputResource;
    syncStarted?: boolean;
    syncOnSave?: boolean;
}
