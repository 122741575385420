import {IWrapperResponse} from '@/api/generic';
import {IUser} from '@/models/User';
import store from '@/store';
import {http, url} from '@/utils/http';

/**
 * Api Accessor for Authentication
 */
export class AuthenticationApi {
    public async login(username: string, password: string) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        const result = await http.post(url('login'), params);
        if (result && result.status === 200) {
            store.commit('setAuthenticated', true);
            store.commit('setUser', result.data.data);
        }
        return result.data as IWrapperResponse<IUser>;
    }

    public async logout() {
        const result = store.dispatch('logout');
    }
}
