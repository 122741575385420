
    import {IHeight} from '@/models/PartProperties';
    import {Component, Prop, Watch, Vue, Model} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class HeightPicker extends Vue {
        @Model('input', {type: Array, default: () => []})
        public readonly value!: IHeight[];

        @Prop({required: true, type: Array, default: () => []})
        public items!: any[];
        @Prop({type: String, default: ''})
        public label!: string;
        @Prop({type: Boolean, default: false})
        public disabled!: boolean;

        public dialog: boolean = false;

        private oldValue: IHeight[] = [];

        public copyValue() {
            this.oldValue = this.$_.clone(this.value);
        }

        public cancel() {
            this.$emit('input', this.oldValue);
            this.dialog = false;
        }
    }
