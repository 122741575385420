import { render, staticRenderFns } from "./AppBase.vue?vue&type=template&id=4b8d5c44&scoped=true&"
import script from "./AppBase.vue?vue&type=script&lang=ts&"
export * from "./AppBase.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b8d5c44",
  null
  
)

export default component.exports