export function SearchAction() {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        Object.defineProperty(target, '__searchAction',
            {configurable: true, enumerable: false, value: descriptor.value});
    };
}

export function SearchClear() {
    return (target: any, propertyKey: string) => {
        Object.defineProperty(target, propertyKey,
            {
                configurable: true,
                enumerable: false,
                // tslint:disable-next-line:only-arrow-functions
                value() {
                    if (typeof this.__searchClearFunction === 'function') {
                        this.__searchClearFunction();
                    }
                },
                writable: true,
            });
    };
}
