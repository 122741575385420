import {Component, Vue} from 'vue-property-decorator';

export interface ShowsMessagesDataObject {
    show: boolean;
    message: string;
}
export interface ShowsMessagesData {
    error: ShowsMessagesDataObject;
    message: ShowsMessagesDataObject;
}

@Component
export class ShowsMessages extends Vue {
    public messages: ShowsMessagesData = {
        error: {
            show: false,
            message: '',
        },
        message: {
            show: false,
            message: '',
        },
    };

    public showMessage(message: string) {
        this.messages.message.show = true;
        this.messages.message.message = message;
    }

    public showError(message: string) {
        this.messages.error.show = true;
        this.messages.error.message = message;
    }
}
