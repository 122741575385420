
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {SearchAction} from '@/decorators/search-action';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {toCurrency} from '@/utils/filters';
    import {Component, Prop, Watch, Vue, Mixins} from 'vue-property-decorator';

    @Component({
        components: {SimpleDeleteDialog, SimpleMessageWrapper},
        filters: {toCurrency},
    })
    export default class ColorThemeList extends Mixins(ListsObjects) {
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Naam', value: 'name'},
            {text: 'Kleur 1', value: 'color1'},
            {text: 'Kleur 2', value: 'color2'},
            {text: 'Kleur 3', value: 'color3'},
            {text: '', sortable: false},
        ];

        public get api() {
            return this.$api.colorThemes;
        }

        @SearchAction()
        public applySearch(value: string) {
            if (value) {
                this.searchCriteria = [new SearchCriterion('*', value), new SearchCriterion('template', 'true')];
            } else {
                this.searchCriteria = [new SearchCriterion('template', 'true')];
            }
            this.resetPagination();
        }

        private async created() {
            this.searchCriteria = [
                new SearchCriterion('template', 'true'),
            ];
        }
    }
