import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors';
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },/*
  theme:  {
    primary: '#999936', // #E53935
    secondary: colors.red.lighten4, // #FFCDD2
    accent: colors.indigo.base, // #3F51B5
  },*/
});
