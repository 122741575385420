import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IPlayground} from '@/models/Playground';
import {IQuotation} from '@/models/Quotation';
import {http, url} from '@/utils/http';

export class PlaygroundApi extends GenericApi<IPlayground> {

    constructor() {
        super('/api/v1/admin/playground');
    }

    public async partList(playgroundId: number) {
        const uri = new URL(url(`/api/v1/admin/playground/${playgroundId}/part-list`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IQuotation>;

    }

    public async duplicate(id: number, newName: string) {
        const uri = new URL(url(`/api/v1/admin/playground/${id}/duplicate`));
        const result = await http.post(uri.href, newName, {headers: {'Content-Type': 'text/plain'}});
        return result.data as IWrapperResponse<IPlayground>;
    }

    public async updateQuotationPrice(model: number[]) {
        const uri = new URL(url(`/api/v1/admin/playground/update-quotation`));
        const result = await http.post(uri.href, model);
        return result.data as IWrapperResponse<any>;
    }
}
