import {GenericApi, IWrapperResponse} from '@/api/generic';
import {http, url} from '@/utils/http';
import {IDampeningFloorCategory} from '@/models/DampeningFloorCategory';
import {IHazard} from '@/models/Hazard';

export class HazardApi extends GenericApi<IHazard> {

    constructor() {
        super('/api/v1/admin/hazard');
    }
}
