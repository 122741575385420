
    import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
    import {ShowsMessagesData} from '@/mixins/ShowsMessages';

    function buildMessageDataDefault() {
        return {
            error: {
                show: false,
                message: '',
            },
            message: {
                show: false,
                message: '',
            },
        };
    }

    @Component({
        components: {},
    })
    export default class SimpleMessageWrapper extends Vue {
        @Prop({default: buildMessageDataDefault}) public messageData!: ShowsMessagesData;
        @Prop({default: 0}) public messageTimeout!: number;
    }
