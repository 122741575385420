import {AuthenticationApi} from '@/api/auth';
import {DampingFloorApi} from '@/api/damping-floor';
import {GenericApi} from '@/api/generic';
import {PartApi} from '@/api/part';
import {HazardApi} from '@/api/hazard';
import {ChecklistItemApi} from '@/api/checklist-item';
import {PlaygroundApi} from '@/api/playground';
import {IRole} from '@/models/Role';
import {IUser} from '@/models/User';
import {IColorTheme} from '@/models/ColorTheme';
import {IColor} from '@/models/Color';

/**
 * Grouping class for all APIs
 */
export class Api {
    public readonly auth: AuthenticationApi;
    public readonly user = new GenericApi<IUser>('/api/v1/admin/user');
    public readonly roles = new GenericApi<IRole>('/api/v1/admin/user/role');
    public readonly playground = new PlaygroundApi();
    public readonly dampingFloors = new DampingFloorApi();
    public readonly parts = new PartApi();
    public readonly colorThemes = new GenericApi<IColorTheme>('/api/v1/admin/color-theme');
    public readonly color = new GenericApi<IColor>('/api/v1/admin/color');
    public readonly hazards = new HazardApi();
    public readonly checklistItems = new ChecklistItemApi();

    constructor() {
        this.auth = new AuthenticationApi();
    }
}

export default Api;
