
import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {SearchAction} from '@/decorators/search-action';
import {ListsObjects} from '@/mixins/ListsObjects';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {toCurrency} from '@/utils/filters';
import {Component, Mixins} from 'vue-property-decorator';

@Component({
    components: {SimpleDeleteDialog, SimpleMessageWrapper},
    filters: {toCurrency},
})
export default class ColorList extends Mixins(ListsObjects) {
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Naam', value: 'name'},
        {text: 'Waarde', value: 'value'},
        {text: 'Voorbeeld', value: 'value'},
        {text: '', sortable: false},
    ];

    public get api() {
        return this.$api.color;
    }

    @SearchAction()
    public applySearch(value: string) {
        if (value) {
            this.searchCriteria = [new SearchCriterion('*', value)];
        } else {
            this.searchCriteria = [];
        }
        this.resetPagination();
    }

    private async created() {
        this.searchCriteria = [];
    }
}
