import {Idable} from '@/models/Idable';

export interface INameDescrObject extends Idable {
    name: string;
    description: string;
}

export type IAgeCategory = INameDescrObject;
export type IDifficultyCategory = INameDescrObject;
export type IMaterial = INameDescrObject;

export interface IHeight extends Idable {
    height: number;
}

export const defaultAgeCategoryId = 1;
export const defaultDifficultyCategoryId = 1;
