
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {IRole} from '@/models/Role';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {timeAgo} from '@/utils/filters';
    import {Component, Prop, Watch, Vue, Mixins} from 'vue-property-decorator';
    import SimpleMessageWrapper from './SimpleMessageWrapper.vue';

    @Component({
        filters: {timeAgo},
        components: {SimpleMessageWrapper},
    })
    export default class LatestPlaygrounds extends Mixins(ListsObjects) {
        public headers: any[] = [
            {text: 'Gebruiker', value: 'user.id'},
            {text: 'Naam', value: 'name'},
            {text: 'Aangemaakt', value: 'createdAt'},
        ];
        public pagination: any = {
            sortDesc: [true],
            page: 1,
            itemsPerPage: 10,
            sortBy: ['createdAt'],
        };

        public get api() {
            return this.$api.playground;
        }

        public displayRoles(roles: IRole[]) {
            return this.$_.map(roles, 'description').join(', ');
        }

        private created() {
            this.searchCriteria = [new SearchCriterion('template', 'false')];
        }
    }
