
    import {IUser} from '@/models/User';
    import {hasRole, hasAnyRole} from '@/utils/auth';
    import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
    import {Action, State} from 'vuex-class';

    @Component({
        components: {},
    })
    export default class AppBase extends Vue {
        public drawer: boolean = true;

        @State((state: any) => state.routing.publicName)
        public publicName!: string;

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        @Action('logout')
        public logoutAction!: () => Promise<void>;

        public hasRole = hasRole;
        public hasAnyRole = hasAnyRole;

        public async logout() {
            await this.logoutAction();
            this.$router.push('/login');
        }
    }
