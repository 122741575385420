import {GenericApi} from '@/api/generic';
import {ShowsMessages, ShowsMessagesData} from '@/mixins/ShowsMessages';
import {Pagination} from '@/utils/api-tools/pagination';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';

@Component
export class ListsObjects<T> extends Mixins(ShowsMessages) {
    public dialog: boolean = false;
    public loadingData: boolean = true;
    public pagination: any = {
        sortDesc: [true],
        page: 1,
        itemsPerPage: 25,
        sortBy: ['id'],
    };
    public totalData: number = 0;
    public headers: any[] = [];
    public objects: Array<{ object: T, deleting: boolean }> = [];

    public get api(): GenericApi<T> {
        throw new Error('public get api() has not been implemented.');
    }

    public searchCriteria?: SearchCriterion[];

    private debouncedLoadData = this.$_.debounce(() => {
        return this.loadListData();
    }, 300);

    @Watch('pagination', {deep: true})
    public watchPagination() {
        return this.debouncedLoadData();
    }

    public async deleteObject(id: number) {
        try {
            await this.api.delete(id);
        } catch (e) {
            this.showError('Er is een fout opgetreden bij het verwijderen.');
        }
        this.dialog = false;
        await this.loadListData();
    }

    public resetPagination() {
        this.pagination = Object.assign({}, this.pagination, {page: 1});
    }

    private async loadListData() {
        this.loadingData = true;
        const firstSort = this.pagination.sortBy[0];
        const firstSortDesc = this.pagination.sortDesc[0];
        const pagination = new Pagination(this.pagination.page - 1, this.pagination.itemsPerPage, firstSort,
            firstSortDesc ? 'DESC' : 'ASC');
        try {
            const {data} = await this.api.list(pagination, this.searchCriteria || []);
            this.totalData = data!.total;
            this.objects = data!.content.map((t) => Object({object: t, deleting: false}));
        } catch (e) {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }
}
