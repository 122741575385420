import RoutingModule from '@/utils/routing';
import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import AuthenticationModule, {AuthenticationState} from '@/utils/auth';

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export type RootType = RootState & { authentication: AuthenticationModule, routing: RoutingModule };

export function _createNewStore() {
  return new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
      authentication: AuthenticationModule,
      routing: RoutingModule,
    },
  }) as Store<RootType>;
}

export default _createNewStore();
