
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {ShowsMessages} from '@/mixins/ShowsMessages';
    import {IAddress} from '@/models/Address';
    import {IRole} from '@/models/Role';
    import {IUser} from '@/models/User';
    import {Pagination} from '@/utils/api-tools/pagination';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {hasRole} from '@/utils/auth';
    import {statusText, timeAgo} from '@/utils/filters';
    import settings from '@/utils/settings';
    import {email, required, max, min, integer} from '@/utils/validation';
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';

    @Component({
        filters: {timeAgo, statusText},
        components: {SimpleMessageWrapper},
    })
    export default class UserEdit extends Mixins(ShowsMessages, ListsObjects) {
        public loadingData: boolean = true;
        public user: IUser | null = null;
        public oldUser: IUser | null = null;
        public roles: IRole[] = [];

        public password: string = '';
        public passwordConfirm: string = '';

        public searchCriteria: SearchCriterion[] = [];
        public headers: any[] = [
            {text: 'Naam', value: 'name'},
            {text: 'Laatst bewerkt', value: 'updatedAt'},
            {text: 'Status', value: 'state'},
        ];

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        @Watch('user.address', { deep: true })
        public addressChanged() {
            if (this.$refs.form && (this.user && this.user.address &&
                !(this.user.address.street ||
                    this.user.address.houseNumber ||
                    this.user.address.zipCode ||
                    this.user.address.city)) ) {
                for (const field of (this.$refs.form as any).inputs) {
                    if (field.shouldValidate) {
                        field.validate();
                    }
                }
            }
        }

        public get rules() {
            return {
                email: [required, email],
                firstName: [max(50)],
                lastName: [max(50)],
                organization: [max(255)],
                phoneNumber: [max(20)],
                address_street: [max(255), this.validAddressInput ],
                address_houseNumber: [max(10), integer, this.validAddressInput ],
                address_zipCode: [max(6), min(6), this.validAddressInput ],
                address_city: [max(255), this.validAddressInput ],
                password: [
                    (value: string) => (!value && !this.isEditing) ? 'Dit veld is verplicht' : true,
                ],
                passwordConfirm: [
                    (value: string) => this.password !== value ? 'Wachtwoorden zijn niet gelijk' : true,
                ],
            };
        }

        public get isCustomer() {
            return this.user && hasRole(this.user, 'customer');
        }

        public get api() {
            return this.$api.playground;
        }

        public async loadData() {
            this.loadingData = true;
            try {
                const {data} = await this.$api.roles.list(new Pagination(0, 25), []);
                this.roles = data!.content.filter((t: IRole) => !t.name.startsWith('admin')
                    || hasRole(this.currentUser, 'admin'));

                if (this.$route.name === 'userEdit') {
                    const response = await this.$api.user.get(Number(this.$route.params.id));
                    this.user = response.data!;

                    delete this.user.createdAt;
                    delete this.user.updatedAt;
                    delete this.user.passwordExpired;

                    this.oldUser = this.$_.cloneDeep(this.user);

                    // make something for Vue to bind to..
                    this.buildEmptyAddresses();
                } else {
                    // make something for Vue to work with..
                    this.user = this.buildEmptyUser();
                }

                // prepare playgrounds
                this.searchCriteria = [new SearchCriterion('user.id', this.$route.params.id || String(-1))];
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            } finally {
                this.loadingData = false;
            }
        }

        public get isEditing() {
            return this.$route.name === 'userEdit';
        }

        public beforeMount() {
            return this.loadData();
        }

        @Watch('$route.params.id')
        public routeChanged() {
            this.loadData();
        }

        public async save() {
            const result = (this.$refs.form as any).validate();
            if (this.user != null && result) {
                this.loadingData = true;
                try {
                    const valueToPost: IUser = this.$_.cloneDeep(this.user);

                    // if password is set, lets set it on the model
                    if (this.password) {
                        valueToPost.password = this.password;
                    }

                    if (this.isAddressEmpty(valueToPost.address!)) {
                        valueToPost.address = null;
                    }

                    if (this.isEditing) {
                        const response = await this.$api.user.save(valueToPost!);
                        this.user = response.data;
                    } else {
                        const response = await this.$api.user.create(valueToPost!);
                        if (!settings.REDIRECT_AFTER_SAVE && response.data && response.data.id) {
                            this.$router.push({name: 'userEdit', params: {id: response.data.id.toString()}});
                        }
                    }

                    if (settings.REDIRECT_AFTER_SAVE) {
                        this.$router.push({name: 'users'});
                    } else {
                        this.showMessage('De gebruiker is opgeslagen');
                    }
                } catch (e) {
                    this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                } finally {
                    this.loadingData = false;
                }
            }
        }

        private isAddressEmpty(address: IAddress | null) {
            return !(address && address.city && address.zipCode && address.houseNumber && address.street);
        }

        private validAddressInput(value: string) {
            if (this.user && this.user.address &&
                (this.user.address.street ||
                    this.user.address.houseNumber ||
                    this.user.address.zipCode ||
                    this.user.address.city)) {
                if (this.isAddressEmpty(this.user.address) && !value) {
                    return 'Vul alle adres velden in';
                }
            }
            return true;
        }

        private buildEmptyUser(): IUser {
            return {
                password: null,
                email: null,
                firstName: null,
                lastName: null,
                disabled: false,
                phoneNumber: null,
                address: {
                    city: '',
                    country: {id: 1},
                    houseNumber: '',
                    street: '',
                    zipCode: '',
                },
                roles: [],
            } as unknown as IUser;
        }

        private buildEmptyAddresses() {
            if (this.user) {
                if (this.user.address == null) {
                    this.user.address = {
                        city: '',
                        country: {id: 1},
                        houseNumber: '',
                        street: '',
                        zipCode: '',
                    };
                }
            }
        }
    }
