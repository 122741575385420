
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {SearchAction} from '@/decorators/search-action';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {Component, Prop, Watch, Vue, Mixins} from 'vue-property-decorator';
    import {IPart} from '@/models/Part';
    import {IPartSpec} from '@/models/PartSpec';

    @Component({
        components: {SimpleDeleteDialog, SimpleMessageWrapper},
    })
    export default class PartList extends Mixins(ListsObjects) {
        public syncInProgressCount: number|null = null;
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Naam', value: 'name'},
            {text: 'Productcode', value: 'productCode'},
            {text: 'Categorie', value: 'category.id'},
            {text: '', sortable: false},
        ];

        public syncCountTimeout?: number;

        public created() {
            this.fetchSyncInProgressCount();
        }

        public get api() {
            return this.$api.parts;
        }

        @SearchAction()
        public applySearch(value: string) {
            if (value) {
                this.searchCriteria = [new SearchCriterion('*', value)];
            } else {
                this.searchCriteria = this.searchCriteria!.filter((t: SearchCriterion) => t.key !== '*');
            }
            this.resetPagination();
        }

        public async synchronizeParts() {
            if (this.syncInProgressCount) {
                if (!confirm('Er lijkt nog een synchronisatie niet afgerond te zijn.' +
                    'weet je zeker dat je opnieuw wilt synchroniseren?')) {
                    return;
                }
            }
            this.syncInProgressCount = 1;
            const result = await this.api.syncParts();
            if (result.success) {
                this.showMessage('Onderdelen worden gesynchroniseerd.');
            } else {
                this.showError('Er is een fout opgetreden bij het synchorniseren van de onderdelen.');
            }
            // wait 2s for checking the fetch-count, to give the sync-call some time to actually update some values
            setTimeout(() => this.fetchSyncInProgressCount, 2000);
        }

        public async fetchSyncInProgressCount() {
            const countResult = await this.api.getSyncInProgressCount();
            if (countResult.success) {
                this.syncInProgressCount = countResult.data;
            } else {
                this.showError('Er is een fout opgetreden bij het synchorniseren van de onderdelen.');
            }
            if (this.syncCountTimeout) {
                clearTimeout(this.syncCountTimeout);
            }
            this.syncCountTimeout = setTimeout(this.fetchSyncInProgressCount, 10000);
        }

        public async syncPart(part: IPart) {
            this.syncInProgressCount = (this.syncInProgressCount || 0) + 1;
            const result = await this.api.syncPart(part.id as any);
            if (result.success) {
                this.showMessage(part.name + ' wordt gesynchroniseerd.');
            } else {
                this.showError('Er is een fout opgetreden bij het synchorniseren van ' + part.name);
            }
        }

        public syncNotDone(part: IPart) {
            if (part.syncStarted) {
                return true;
            }
            return !!part.partSpecs.find((spec: IPartSpec|any) => {
                return spec.syncReferenceId && spec.syncStartedAt > spec.syncStoppedAt;
            });
        }

        public canBeSynced(part: IPart) {
            return !!part.partSpecs.find((spec: IPartSpec|any) => {
                return spec.syncReferenceId > 0;
            });
        }
    }
