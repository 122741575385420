
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {SearchAction, SearchClear} from '@/decorators/search-action';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {PlaygroundStatus} from '@/models/Playground';
    import {IUser} from '@/models/User';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {hasRole} from '@/utils/auth';
    import {statusText, timeAgo} from '@/utils/filters';
    import {url} from '@/utils/http';
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';
    import {PartApi} from '@/api/part';
    import {IPart} from '@/models/Part';
    import {Pagination} from '@/utils/api-tools/pagination';
    import SimpleDuplicateDialog from '@/components/SimpleDuplicateDialog.vue';

    @Component({
        filters: {
            timeAgo,
            statusText,
        },
        components: {SimpleDuplicateDialog, SimpleDeleteDialog, SimpleMessageWrapper},
    })
    export default class QuotationRequestList extends Mixins(ListsObjects) {
        public state: PlaygroundStatus | null = null;
        public states: PlaygroundStatus[] = [
            PlaygroundStatus.CONCEPT,
            PlaygroundStatus.NEW,
            PlaygroundStatus.WAITING_FOR_APPROVAL,
            PlaygroundStatus.APPROVED,
            PlaygroundStatus.ORDERED,
            PlaygroundStatus.READY_FOR_ASSEMBLY,
            PlaygroundStatus.DELIVERED,
            PlaygroundStatus.CANCELED,
        ];
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Naam', value: 'name'},
            {text: 'Gebruiker', value: 'user.id'},
            {text: 'Status', value: 'state'},
            {text: 'Bewerkt op', value: 'updatedAt'},
            {text: '', sortable: false},
        ];

        public duplicateDialog: boolean = false;
        public duplicateId: number = 0;
        public partData: IPart[] = [];
        public partProductCodeFilter: string | null = null;

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        @SearchClear()
        public searchClearFunction!: () => void;

        public get api() {
            return this.$api.playground;
        }

        public get isAdmin() {
            return hasRole(this.currentUser, 'admin');
        }

        public async openDuplicationDialog(id: number) {
            this.duplicateDialog = true;
            this.duplicateId = id;
        }

        public async duplicate(id: number, newName: string = '') {
            const response = await this.$api.playground.duplicate(id, newName);
            this.$router.push({name: 'quotationsEdit', params: {id: String(response.data!.id!)}});
        }

        public url(path: string) {
            return url(path);
        }

        @Watch('state')
        @Watch('partProductCodeFilter')
        public filterChange() {
            if (this.state) {
                this.searchCriteria = [
                    new SearchCriterion('state', `${this.state}`),
                    new SearchCriterion('template', 'false'),
                ];
            } else {
                this.searchCriteria = this.searchCriteria!.filter((t: SearchCriterion) => t.key !== 'state');
            }

            if (this.partProductCodeFilter) {
                this.searchCriteria = this.searchCriteria!
                    .filter((t: SearchCriterion) => t.key !== 'parts.partSpec.part.productCode');
                this.searchCriteria.push(new SearchCriterion('parts.partSpec.part.productCode',
                    `${this.partProductCodeFilter}`));
            } else {
                this.searchCriteria = this.searchCriteria!
                    .filter((t: SearchCriterion) => t.key !== 'parts.partSpec.part.productCode');
            }

            this.pagination = Object.assign({}, this.pagination, {page: 1});
        }

        @SearchAction()
        public applySearch(value: string) {
            if (value) {
                this.state = null;
                this.searchCriteria = [new SearchCriterion('*', value), new SearchCriterion('template', 'false')];

                if (this.partProductCodeFilter) {
                    this.searchCriteria.push(new SearchCriterion('parts.partSpec.part.productCode',
                        `${this.partProductCodeFilter}`));
                }
            } else {
                this.searchCriteria = this.searchCriteria!.filter((t: SearchCriterion) => t.key !== '*');
            }
            this.resetPagination();
        }

        private async created() {
            this.searchCriteria = [
                new SearchCriterion('template', 'false'),
            ];

            const partApi: PartApi = new PartApi();
            try {
                const parts = await partApi.list(new Pagination(0, -1), []);
                if (parts.data) {
                    this.partData = parts.data.content;
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            }
        }
        private mounted() {
            this.searchClearFunction();
        }
    }
