
    import FileInput from '@/components/FileInput.vue';
    import HeightPicker from '@/components/HeightPicker.vue';
    import ImageInput from '@/components/ImageInput.vue';
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ShowsMessages} from '@/mixins/ShowsMessages';
    import {IUser} from '@/models/User';
    import {toCurrency} from '@/utils/filters';
    import settings from '@/utils/settings';
    import {integer, max, required} from '@/utils/validation';
    import Vue from 'vue';
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';
    import {HazardType, IHazard} from '@/models/Hazard';

    @Component({
        filters: {toCurrency},
        components: {SimpleDeleteDialog, HeightPicker, FileInput, ImageInput, SimpleMessageWrapper},
    })
    export default class HazardEdit extends Mixins(ShowsMessages) {
        public loadingData: boolean = true;
        public hazard: IHazard | null = null;

        public types: Array<{ label: string, value: HazardType }> = [
            {label: 'Gevaar', value: HazardType.DANGER},
            {label: 'Norm', value: HazardType.NORM},
        ];

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        public get rules() {
            return {
                type: [required],
                name: [required, max(255)],
                alwaysShow: [required, integer],
                defaultValue: [required, integer],
            };
        }

        public async loadData() {
            this.loadingData = true;
            try {
                if (this.isEditing) {
                    const response = await this.$api.hazards.get(Number(this.$route.params.id));
                    this.hazard = response.data;
                } else {
                    this.hazard = {
                        type: null as any,
                        name: '',
                        alwaysShow: false,
                        defaultValue: false,
                    };
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            } finally {
                this.loadingData = false;
            }
        }

        public get isEditing() {
            return this.$route.name === 'hazardEdit';
        }

        public beforeMount() {
            return this.loadData();
        }

        @Watch('$route.params.id')
        public routeChanged() {
            this.loadData();
        }

        public async save() {
            let validated = this.validate();
            validated = (this.$refs.form as any).validate() && validated;

            if (this.hazard != null && validated) {
                this.loadingData = true;
                try {
                    const requestObject = this.$_.cloneDeep(this.hazard);

                    if (!this.isEditing) {
                        const response = await this.$api.hazards.create(requestObject);

                        if (!settings.REDIRECT_AFTER_SAVE && response.data && response.data.id) {
                            this.$router.push({name: 'hazardEdit', params: {id: response.data.id.toString()}});
                        }
                    } else {
                        const response = await this.$api.hazards.save(requestObject);
                    }

                    if (settings.REDIRECT_AFTER_SAVE) {
                        this.$router.push({name: 'hazards'});
                    } else {
                        this.showMessage('Het gevaar is opgeslagen');
                    }
                } catch (e) {
                    this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                } finally {
                    this.loadingData = false;
                }
            }
        }

        private validate() {
            const vues = this.findDescendants(this, (item: Vue) => {
                return (item.$options.name === 'FileInput' || item.$options.name === 'ImageInput');
            });


            let result = true;
            for (const vue of vues) {
                result = (vue as any).validate() && result;
            }
            return result;
        }

        private findDescendants(item: Vue, matcher: (item: Vue) => boolean): Vue[] {
            const result: Vue[] = [];
            for (const descendant of item.$children) {
                if (matcher(descendant)) {
                    result.push(descendant);
                }
                result.push(...result, ...this.findDescendants(descendant, matcher));
            }
            return result;
        }
    }
