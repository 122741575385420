import Vue from 'vue';
import {Dayjs, extend, locale} from 'dayjs';
import 'dayjs/locale/nl';
import relativeTime from 'dayjs/plugin/relativeTime';

extend(relativeTime);
locale('nl');

Object.defineProperty(Vue.prototype, '$dayjs', {value: Dayjs});

declare module 'vue/types/options' {
    interface ComponentOptions<V extends Vue> {
        dayjs?: typeof Dayjs;
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $dayjs: typeof Dayjs;
    }
}

