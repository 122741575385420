
    import {FileInputResource} from '@/models/Part';
    import {url} from '@/utils/http';
    import {Component, Prop, Watch, Vue, Model} from 'vue-property-decorator';
    import Dropzone from './Dropzone.vue';
    import {urlBasename} from '@/utils/strings';

    @Component({
        name: 'FileInput',
        components: {Dropzone},
    })
    export default class FileInput extends Vue {
        @Model('input', {type: Object, default: () => new Object()})
        public readonly value!: FileInputResource;

        @Prop({default: null})
        public url!: string|null;

        @Prop({default: ''})
        public label!: string;

        @Prop({default: false, type: Boolean})
        public disabled!: boolean;

        @Prop({default: false, type: Boolean})
        public required!: boolean;

        @Prop({default: () => []})
        public accept!: string | string[];

        public isError: boolean = false;

        public fileName?: string = '';

        public isDragging = false;

        public created() {
            if (this.value.url) {
                this.fileName = urlBasename(this.value.url);
            }
        }

        public validate() {
            const isValidEmpty = !this.required && !this.value.file;
            const isValid = isValidEmpty ||
                (
                    this.required && ((this.value.file &&
                        (
                            (Array.isArray(this.accept) &&
                                this.$_.some(this.accept,
                                    this.$_.curry<string|undefined, string|undefined, number|undefined, boolean>(this.$_.endsWith, 3)(this.value.file.name, this.$_, undefined))) ||
                            (this.$_.endsWith(this.value.file.name, this.accept as string))
                        )) || (this.value.file == null && !!this.value.url))
                );

            this.isError = !isValid;

            return isValid;
        }

        public onFilePicked(e: any) {
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.processFile(files[0]);
            } else {
                this.fileName = undefined;
                this.$emit('input', {});
            }
        }

        public fileDropped(e: any) {
            const droppedFiles = e.dataTransfer.files;
            if (!droppedFiles) {
                return;
            }

            if (droppedFiles[0] !== undefined) {
                this.processFile(droppedFiles[0]);
            }
        }

        @Watch('value')
        private watchInput() {
            if (this.value.directUrl) {
                this.fileName = this.value.url;
            } else if (this.value && this.value.file) {
                this.fileName = this.value.file.name;
                this.$nextTick(() => {
                    this.validate();
                });
            }
        }

        private processFile(file: File) {
            this.fileName = file.name;
            if (this.fileName!.lastIndexOf('.') <= 0) {
                return;
            }

            const val = {
                file,
            };
            this.$emit('input', val);
            this.$nextTick(() => {
                this.validate();
            });
        }

        public get valueUrlNoData() {
            if (this.value.directUrl) {
                return this.value.url;
            }
            if (this.value && this.value.url && !this.$_.startsWith(this.value.url, 'data:')) {
                return url(`/static/uploads/${this.value.url}`);
            }
            return this.url;
        }
    }
