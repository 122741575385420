
    import {Component, Vue, Model} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class SimpleDuplicateDialog extends Vue {
        @Model('input', {type: Boolean})
        public readonly dialog!: boolean;

        public duplicateName: string = '';

        public cancelDuplication() {
            this.duplicateName = '';
            this.$emit('cancel');
        }
    }
