
    import {FileInputResource} from '@/models/Part';
    import {url} from '@/utils/http';
    import {Component, Prop, Watch, Vue, Model} from 'vue-property-decorator';
    import Dropzone from './Dropzone.vue';


    @Component({
        name: 'ImageInput',
        components: {Dropzone},
    })
    export default class ImageInput extends Vue {
        @Model('input', {type: Object, default: () => new Object()})
        public readonly value!: FileInputResource;

        @Prop({default: ''})
        public label!: string;

        @Prop({default: false, type: Boolean})
        public disabled!: boolean;

        @Prop({default: false, type: Boolean})
        public required!: boolean;

        @Prop({default: () => []})
        public accept!: string | string[];

        @Prop({default: null})
        public clearable!: string | boolean;

        public isError: boolean = false;

        public imageName: string | null = null;

        public isDragging = false;

        public get valueIsDataUrl() {
            return this.value && this.value.url && this.$_.startsWith(this.value.url, 'data:');
        }

        public get imageSrc() {
            if (this.valueIsDataUrl) {
                return this.value.url;
            } else if (this.value && this.value.url) {
                return url(`/static/uploads/${this.value.url}`);
            }
            return null;
        }

        public get valueUrlNoData() {
            if (this.value.directUrl) {
                return this.value.url;
            }
            if (this.value && this.value.url && this.$_.startsWith(this.value.url, 'data:')) {
                return null;
            }
            if (this.value && this.value.url) {
                return url(`/static/uploads/${this.value.url}`);
            }
            return null;
        }

        public get isPdf() {
            if (this.imageName) {
                return this.imageName.endsWith('.pdf');
            }
            return this.value && this.value.url && this.value.url.endsWith('.pdf');
        }

        public get isClearable() {
            return this.value && (this.value.url || this.value.file);
        }

        public validate() {
            const isValidEmpty = !this.required && this.value.file == null;
            const isValid = isValidEmpty ||
                (
                    ((this.value.file != null &&
                        (
                            (Array.isArray(this.accept) &&
                                this.$_.some(this.accept,
                                    this.$_.curry<string|undefined, string|undefined, number|undefined, boolean>(this.$_.endsWith, 3)(this.value.file.name, this.$_, undefined))) ||
                            (this.$_.endsWith(this.value.file.name, this.accept as string))
                        )) || (this.value.file == null && !!this.value.url))
                );

            this.isError = !isValid;

            return isValid;
        }

        public onFilePicked(e: any) {
            this.imageName = null;
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.imageName = files[0].name;
                if (this.imageName!.lastIndexOf('.') <= 0) {
                    return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener('load', () => {
                    const val = {
                        file: files[0],
                        url: fr.result,
                    };
                    this.$emit('input', val);
                    this.$nextTick(() => {
                        this.validate();
                    });
                });
            } else {
                this.imageName = null;
                this.$emit('input', {});
            }
        }

        private processFile(file: File) {
            this.imageName = file.name;
            if (this.imageName!.lastIndexOf('.') <= 0) {
                return;
            }
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.addEventListener('load', () => {
                const val = {
                    file,
                    url: fr.result,
                };
                this.$emit('input', val);
                this.$nextTick(() => {
                    this.validate();
                });
            });
        }

        private clear() {
            this.$emit('input', {url: undefined, file: undefined});
            this.$nextTick(() => {
                this.validate();
            });
        }
    }
