
    import FileInput from '@/components/FileInput.vue';
    import HeightPicker from '@/components/HeightPicker.vue';
    import ImageInput from '@/components/ImageInput.vue';
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ShowsMessages} from '@/mixins/ShowsMessages';
    import {IPlayground, PlaygroundStatus} from '@/models/Playground';
    import {IQuotation} from '@/models/Quotation';
    import {IUser} from '@/models/User';
    import {Pagination} from '@/utils/api-tools/pagination';
    import {hasRole} from '@/utils/auth';
    import {toCurrency} from '@/utils/filters';
    import {http, url} from '@/utils/http';
    import settings from '@/utils/settings';
    import {integer, max, required} from '@/utils/validation';
    import Vue from 'vue';
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';
    import {IColor} from '@/models/Color';

    @Component({
        filters: {toCurrency},
        components: {SimpleDeleteDialog, HeightPicker, FileInput, ImageInput, SimpleMessageWrapper},
    })
    export default class PlaygroundEdit extends Mixins(ShowsMessages) {

        public loadingData: boolean = true;
        public playground: IPlayground | null = null;
        public oldPlayground: IPlayground | null = null;
        public quote: IQuotation | null = null;

        public users: IUser[] = [];
        public mechanics: IUser[] = [];
        public inspectors: IUser[] = [];
        public search: string = '';
        public mechanicSearch: string = '';
        public inspectorSearch: string = '';
        public searchLoading: boolean = false;
        public colorOptions: IColor[] = [];

        public states: Array<{ label: string; value: PlaygroundStatus, disabled: boolean }> = [
            {label: 'Concept', value: PlaygroundStatus.CONCEPT, disabled: false},
            {label: 'Wachtend op goedkeuring TnT', value: PlaygroundStatus.NEW, disabled: false},
            {label: 'Wachtend op goedkeuring Klant', value: PlaygroundStatus.WAITING_FOR_APPROVAL, disabled: false},
            {label: 'Goedgekeurd', value: PlaygroundStatus.APPROVED, disabled: false},
            {label: 'Besteld', value: PlaygroundStatus.ORDERED, disabled: false},
            {label: 'Klaar voor assemblage', value: PlaygroundStatus.READY_FOR_ASSEMBLY, disabled: false},
            {label: 'Opgeleverd', value: PlaygroundStatus.DELIVERED, disabled: false},
            {label: 'Geannuleerd', value: PlaygroundStatus.CANCELED, disabled: false},
        ];

        // Filled in created()
        public stateMap: Map<PlaygroundStatus, Array<{s: PlaygroundStatus, validated: boolean}>> = new Map();

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        public get rules() {
            return {
                state: [required, integer],
                name: [required, max(255)],
                description: [max(255)],
                comment: [max(1000)],
                location: [max(255)],
                constructionPrice: [max(255), integer],
            };
        }

        public get isAdmin(): boolean {
            return hasRole(this.currentUser, 'admin');
        }

        public get isPlaygroundWaitingForApprovalOrApproved(): boolean {
            return !!this.oldPlayground && (this.oldPlayground!.state === PlaygroundStatus.WAITING_FOR_APPROVAL ||
                this.oldPlayground!.state === PlaygroundStatus.APPROVED);
        }

        public get isPlaygroundNew(): boolean {
            return !!this.playground && this.playground!.state === PlaygroundStatus.NEW;
        }

        public get hasModifiedParts(): boolean {
            return !!this.quote && this.quote.rows.filter((row) => row.modifiedAfterPlayground).length > 0;
        }

        private oldUser?: IUser = undefined;
        private oldMechanics: IUser[] = [];
        private oldInspectors: IUser[] = [];

        private get validateUrl(): string {
            if (this.playground != null) {
                return settings.CONFIGURATOR_URL + '/builder?playground=' + this.playground!.id;
            }
            return '';
        }

        public async loadData() {
            this.loadingData = true;
            try {
                this.colorOptions = (await this.$api.color.list(new Pagination(0, -1), [])).data?.content || [];
                const response = await this.$api.playground.get(Number(this.$route.params.id));
                this.playground = response.data;
                this.oldPlayground = this.$_.cloneDeep(this.playground);
                Vue.set(this.playground!, 'customIconObject', {url: this.playground!.customIcon});

                this.oldUser = this.playground!.user as IUser;

                if (!this.isAdmin) {
                    this.users.push(this.playground!.user as IUser);
                }

                const partListResponse = await this.$api.playground.partList(Number(this.$route.params.id));
                this.quote = partListResponse.data!;

                const mechanicResponse = await this.$api.user.list(new Pagination(0, -1), [{
                    key: 'roles.name',
                    value: 'mechanic',
                }]);

                const inspectorResponse = await this.$api.user.list(new Pagination(0, -1), [{
                    key: 'roles.name',
                    value: 'inspector',
                }]);

                this.oldMechanics = this.mechanics = mechanicResponse.data!.content;
                this.oldInspectors = this.inspectors = inspectorResponse.data!.content;
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            } finally {
                this.loadingData = false;
            }
        }

        public beforeMount() {
            return this.loadData();
        }

        public async save() {
            let validated = this.validate();
            validated = (this.$refs.form as any).validate() && validated;

            if (this.playground != null && validated) {
                this.loadingData = true;
                try {
                    const requestObject = this.$_.cloneDeep(this.playground);

                    if (!requestObject.constructionPrice) {
                        delete requestObject.constructionPrice;
                    }

                    if (requestObject.customIconObject) {
                        if (!requestObject.customIconObject.url && !requestObject.customIconObject.file) {
                            requestObject.customIcon = undefined;
                        }
                    }

                    const response = await this.$api.playground.save(requestObject);
                    if (response.data) {
                        await this.uploadFiles(response.data);
                    }

                    if (settings.REDIRECT_AFTER_SAVE) {
                        this.$router.push({name: this.playground.template ? 'playgrounds' : 'quotations'});
                    } else {
                        this.showMessage('Het toestel is opgeslagen');
                        this.loadData();
                    }
                } catch (e) {
                    this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                } finally {
                    this.loadingData = false;
                }
            }
        }

        public formatDescription(description: string): string {
            return description && description.replace(/#[0-9a-zA-Z]{6}/g, this.colorCodeToName);
        }

        public colorCodeToName(colorCode: string): string {
            const color = this.colorOptions.find((c) => '#' + c.value.toUpperCase() == colorCode || c.value.toLowerCase() == colorCode);
            return color ? color.name : colorCode;
        }

        public url(path: string) {
            return url(path);
        }

        @Watch('playground', {deep: true})
        private watchToUpdateStates() {
            if (this.playground != null) {
                this.stateMap.get(this.playground.state)!.forEach((possibleState) => {
                    const stateOption = this.states.find((option) => option.value === possibleState.s);
                    if (stateOption) {
                        // disable option if validated is required and playground not validated
                        stateOption.disabled = possibleState.validated && !this.playground!.validated;
                    }
                });
            }
        }

        private stateSelectionChanged() {
            this.save();
        }

        private created() {
            this.states.forEach((t) => {
                this.stateMap.set(t.value, [
                    {s: PlaygroundStatus.CONCEPT, validated: false},
                    {s: PlaygroundStatus.NEW, validated: false},
                    {s: PlaygroundStatus.WAITING_FOR_APPROVAL, validated: true},
                    {s: PlaygroundStatus.APPROVED, validated: true},
                    {s: PlaygroundStatus.ORDERED, validated: true},
                    {s: PlaygroundStatus.READY_FOR_ASSEMBLY, validated: true},
                    {s: PlaygroundStatus.DELIVERED, validated: true},
                    {s: PlaygroundStatus.CANCELED, validated: false},
                ]);
            });
        }

        private async uploadFiles(responsePart: IPlayground) {
            if (this.playground) {
                if (this.playground.customIconObject && this.playground.customIconObject.file) {
                    const formData = new FormData();
                    formData.append('file', this.playground.customIconObject.file,
                        this.playground.customIconObject.file.name);
                    const response = await http.post(url(`/api/v1/admin/playground/${responsePart.id}/custom-icon`),
                        formData);
                }
            }
        }

        @Watch('search')
        private async onUserSearch() {
            if (this.searchLoading || !this.isAdmin) {
                return;
            }

            if (!this.search) {
                this.users = [];
                this.users.push(this.oldUser as IUser);
                return;
            }

            this.searchLoading = true;
            try {
                const response = await this.$api.user.list(new Pagination(0, -1), [{
                    key: 'email',
                    value: this.search!,
                }]);

                this.users = response.data!.content;
            } finally {
                this.searchLoading = false;
            }
        }

        @Watch('mechanicSearch')
        private async onMechanicSearch() {
            if (this.searchLoading || !this.isAdmin) {
                return;
            }

            if (!this.mechanicSearch) {
                this.mechanics = [];
                this.mechanics.push(...this.mechanics, ...this.oldMechanics);
                return;
            }

            this.searchLoading = true;
            try {
                const response = await this.$api.user.list(new Pagination(0, -1), [
                    {
                        key: 'email',
                        value: this.mechanicSearch!,
                    },
                    {
                        key: 'roles.name',
                        value: 'mechanic',
                    },
                ]);

                this.mechanics = response.data!.content;
            } finally {
                this.searchLoading = false;
            }
        }

        @Watch('inspectorSearch')
        private async onInspectorSearch() {
            if (this.searchLoading || !this.isAdmin) {
                return;
            }

            if (!this.inspectorSearch) {
                this.inspectors = [];
                this.inspectors.push(...this.inspectors, ...this.oldInspectors);
                return;
            }

            this.searchLoading = true;
            try {
                const response = await this.$api.user.list(new Pagination(0, -1), [
                    {
                        key: 'email',
                        value: this.inspectorSearch!,
                    },
                    {
                        key: 'roles.name',
                        value: 'inspector',
                    },
                ]);

                this.inspectors = response.data!.content;
            } finally {
                this.searchLoading = false;
            }
        }

        private validate() {
            const vues = this.findDescendants(this, (item: Vue) => {
                return (item.$options.name === 'FileInput' || item.$options.name === 'ImageInput');
            });


            let result = true;
            for (const vue of vues) {
                result = (vue as any).validate() && result;
            }
            return result;
        }

        private findDescendants(item: Vue, matcher: (item: Vue) => boolean): Vue[] {
            const result: Vue[] = [];
            for (const descendant of item.$children) {
                if (matcher(descendant)) {
                    result.push(descendant);
                }
                result.push(...result, ...this.findDescendants(descendant, matcher));
            }
            return result;
        }
    }
