
    import Dropzone from '@/components/Dropzone.vue';
    import FileInput from '@/components/FileInput.vue';
    import HeightPicker from '@/components/HeightPicker.vue';
    import ImageInput from '@/components/ImageInput.vue';
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ShowsMessages} from '@/mixins/ShowsMessages';
    import {IConstructionPart} from '@/models/ConstructionPart';
    import {IPart, IPartDefaultColors, PartSizeCategory} from '@/models/Part';
    import {IPartCategory} from '@/models/PartCategory';
    import {
        defaultAgeCategoryId,
        defaultDifficultyCategoryId,
        IAgeCategory,
        IDifficultyCategory,
        IHeight,
        IMaterial,
    } from '@/models/PartProperties';
    import {IPartSpec} from '@/models/PartSpec';
    import {IUser} from '@/models/User';
    import {toCurrency} from '@/utils/filters';
    import {http, url} from '@/utils/http';
    import settings from '@/utils/settings';
    import {uuid} from '@/utils/strings';
    import {integer, max, min, number, regex, required} from '@/utils/validation';
    import Vue from 'vue';
    import {timeAgo} from '@/utils/filters';
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';
    import {IHazard} from '@/models/Hazard';
    import {IChecklistItem} from '@/models/ChecklistItem';
    import {Pagination} from '@/utils/api-tools/pagination';
    import {IPartSpecSync, ISyncConstructionPart, ISyncPartSpec} from '@/models/PartSpecSync';
    import {IColor} from '@/models/Color';

    @Component({
        filters: {toCurrency, timeAgo },
        components: {Dropzone, SimpleDeleteDialog, HeightPicker, FileInput, ImageInput, SimpleMessageWrapper},
    })
    export default class PartEdit extends Mixins(ShowsMessages) {
        public loadingData: boolean = true;
        public part: IPart | null = null;
        public oldPart: IPart | null = null;

        public partCategories: IPartCategory[] = [];
        public ageCategories: IAgeCategory[] = [];
        public difficultyCategories: IDifficultyCategory[] = [];
        public hazards: IHazard[] = [];
        public checklistItems: IChecklistItem[] = [];
        public heights: IHeight[] = [];
        public materials: IMaterial[] = [];
        public syncablePartSpecs: IPartSpecSync[] = [];
        public partSizes: Array<{ label: string; value: PartSizeCategory }> = [
            {label: 'Klein', value: PartSizeCategory.SMALL},
            {label: 'Middel', value: PartSizeCategory.MEDIUM},
            {label: 'Groot', value: PartSizeCategory.LARGE},
        ];

        public colorOptions: IColor[] = [];

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        public get rules() {
            return {
                category: [required],
                productCode: [required],
                name: [required, max(255)],
                description: [required, max(255)],
                popup: [max(255)],
                ageCategory: [required],
                difficultyCategory: [required],
                hazards: [],
                checklistItems: [],
                materials: [required],
                sizeCategory: [required],
                partSpec: {
                    syncReferenceId: [],
                    price: [required, integer],
                    heights: [required],
                    constructionPart: {
                        name: [required],
                        count: [required, integer],
                        dimensions: [max(255)],
                    },
                },
                colors: {
                    groupName: [required],
                    color: [required, min(6), max(6), regex(/^[a-fA-F0-9]+$/)],
                },
            };
        }

        private colorsAutofilled: boolean | null = null;

        public async loadSyncablePartSpecs() {
            const syncablePartSpecsResponse = await this.$api.parts.getSyncablePartSpecs();
            if (syncablePartSpecsResponse.success) {
                this.syncablePartSpecs = syncablePartSpecsResponse.data!.content;
            }

            this.syncablePartSpecs.reverse();
            this.syncablePartSpecs.push({id: null, name: 'Geen koppeling'});
            this.syncablePartSpecs.reverse();
        }

        public async loadData() {
            this.loadingData = true;

            this.loadSyncablePartSpecs(); // Do NOT await this methods as it is fairly slow.

            try {
                await this.loadListData();
                this.colorOptions = (await this.$api.color.list(new Pagination(0, -1), [])).data?.content || [];

                if (this.isEditing) {
                    const response = await this.$api.parts.get(Number(this.$route.params.id));
                    this.part = response.data;

                    if (this.part && this.part.partDefaultColors.length) {
                        this.colorsAutofilled = false;
                    }

                    // for reactivity
                    Vue.set(this.part as any, 'iconObject', {url: this.part!.icon});
                    for (const partSpec of this.part!.partSpecs) {
                        Vue.set(partSpec, 'uuid', uuid());
                        Vue.set(partSpec, 'svgModelObject', {url: partSpec.svgModel});
                        Vue.set(partSpec, 'gltfModelObject', {url: partSpec.gltfModel});
                        Vue.set(partSpec, 'binModelObject', {url: partSpec.binModel});
                        Vue.set(partSpec, 'assemblyManualObject', {url: partSpec.assemblyManual});

                        if (partSpec.constructionParts != null) {
                            for (const constPart of partSpec.constructionParts) {
                                Vue.set(constPart, 'uuid', uuid());
                                Vue.set(constPart, 'iconObject', {url: constPart.icon});
                            }
                        }
                    }
                    this.oldPart = this.$_.cloneDeep(this.part);
                } else {
                    this.oldPart = null;
                    const defaultAgeCategory = this.ageCategories.find((ageCategory) => {
                        return ageCategory.id === defaultAgeCategoryId;
                    });
                    const defaultDifficultyCategory = this.difficultyCategories.find((difficultyCategory) => {
                        return difficultyCategory.id === defaultDifficultyCategoryId;
                    });
                    this.part = {
                        category: null as any,
                        description: '',
                        popup: '',
                        disabled: false,
                        published: false,
                        icon: undefined as any,
                        iconObject: {url: undefined, file: undefined},
                        name: '',
                        parent: null,
                        partSpecs: [],
                        productCode: '',
                        ageCategories: [defaultAgeCategory as IAgeCategory],
                        difficultyCategories: [defaultDifficultyCategory as IDifficultyCategory],
                        hazards: [],
                        checklistItems: [],
                        sizeCategory: null as any,
                        materials: [],
                        partDefaultColors: [],
                    };
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            } finally {
                this.loadingData = false;
            }
        }

        public async syncPartSpec(partSpec: IPartSpec) {
            const result = await this.$api.parts.syncPartSpec(partSpec.id as any);
            if (result.success) {
                this.showMessage('Onderdeel specificatie wordt gesynchroniseerd.');
            } else {
                this.showError('Er is een fout opgetreden bij het synchorniseren van onderdeel specificatie');
            }
        }

        public get rows() {
            if (this.part) {
                return this.$_.range(0, Math.ceil(this.part.partSpecs.length / 2));
            }
            return [];
        }

        public get isEditing() {
            return this.$route.name === 'partEdit';
        }

        public beforeMount() {
            return this.loadData();
        }

        @Watch('$route.params.id')
        public routeChanged() {
            this.loadData();
        }

        public async syncReferenceIdChange(partSpec: IPartSpec) {
            partSpec.syncStartedAt = null;
            partSpec.syncStoppedAt = null;
            partSpec.couplingChanged = this.oldPart != null;

            if (!partSpec.syncReferenceId) {
                for (const constructionPart of partSpec.constructionParts) {
                    constructionPart.iconObject = undefined;
                    constructionPart.icon = undefined;
                }
                return;
            }

            if (this.part != null) {
                // Auto select synchronisation
                this.part.syncOnSave = true;
            }

            const syncablePartSpecsResponse = await this.$api.parts.getSyncablePartSpec(partSpec.syncReferenceId);
            if (syncablePartSpecsResponse.success) {
                const syncPartSpec: ISyncPartSpec = syncablePartSpecsResponse.data!;
                partSpec.assemblyManualObject = {
                    url: syncPartSpec.pdf,
                    directUrl: true,
                };
                partSpec.assemblyManual = syncPartSpec.pdf;
                partSpec.constructionParts = Object.values(syncPartSpec.parts).map((syncConstructionPart) => {
                    return {
                        name: syncConstructionPart.name,
                        syncReferenceId: syncConstructionPart.id,
                        count: syncConstructionPart.count,
                        dimensions: syncConstructionPart.dimensions,
                        icon: syncConstructionPart.image,
                        iconObject: {
                            url: syncConstructionPart.image,
                            directUrl: true,
                        },
                    } as IConstructionPart;
                });
            }
        }

        public async autofillColors(value: any) {
            const autofillColors: { [key: string]: string } = {
                color1: '0135A7',
                color2: 'B23047',
                color3: 'FCAF30',
                color4: '0A5644',
            };

            if (this.colorsAutofilled == null || this.colorsAutofilled) {
                this.part!.partDefaultColors.splice(0, this.part!.partDefaultColors.length);
                const allSvgModelObjects = this.part!.partSpecs.map((t) => t.svgModelObject);
                const colors: string[] = [];
                for (const obj of allSvgModelObjects) {
                    if (obj && obj.file) {
                        const p = new Promise<string>((resolve, reject) => {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                                resolve(fileReader.result as string);
                            };
                            fileReader.onerror = () => {
                                reject();
                            };
                            fileReader.readAsText(obj.file!);
                        });
                        const resultText = await p;
                        const parser = new DOMParser();
                        const document = parser.parseFromString(resultText, 'image/svg+xml');
                        const elements = document.querySelectorAll('[class*="color"]');
                        for (const el of elements) {
                            el.classList.forEach((t) => {
                                if (t.match(/^color[0-9]+$/)) {
                                    if (colors.indexOf(t) === -1) {
                                        colors.push(t);
                                    }
                                }
                            });
                        }
                    }
                }

                for (const color of colors) {
                    this.part!.partDefaultColors.push({
                        color: Object.prototype.hasOwnProperty.call(autofillColors, color) ? autofillColors[color] : '',
                        groupName: color,
                        uuid: uuid(),
                        part: null as any,
                    });
                }

                this.colorsAutofilled = true;
            }
        }

        public processPartSpecFiles(partSpec: IPartSpec, files: File[]) {
            for (const file of files) {
                if (file.name.endsWith('.gltf')) {
                    partSpec.gltfModelObject = {file};
                } else if (file.name.endsWith('.bin')) {
                    partSpec.binModelObject = {file};
                } else if (file.name.endsWith('.svg')) {
                    partSpec.svgModelObject = {file};
                    this.autofillColors(undefined);
                } else if (file.name.endsWith('.pdf')) {
                    partSpec.assemblyManualObject = {file};
                }
            }
        }

        public async save() {
            let validated = this.validate();
            validated = (this.$refs.form as any).validate() && validated;

            if (this.part != null && validated) {
                this.loadingData = true;
                try {
                    const requestObject = this.$_.cloneDeep(this.part);
                    delete requestObject.iconObject;
                    requestObject.partSpecs.forEach((partSpec: IPartSpec) => {
                        delete partSpec.svgModelObject;
                        delete partSpec.gltfModelObject;
                        delete partSpec.binModelObject;
                        delete partSpec.assemblyManualObject;

                        if (partSpec.constructionParts) {
                            partSpec.constructionParts.forEach((constPart: IConstructionPart) => {
                                delete constPart.iconObject;
                            });
                        }
                    });

                    const wasSyncLastSave = this.part.syncOnSave;

                    if (!this.isEditing) {
                        const response = await this.$api.parts.create(requestObject);

                        await this.uploadFiles(response.data!);

                        if (!settings.REDIRECT_AFTER_SAVE && response.data && response.data.id) {
                            this.$router.push({name: 'partEdit', params: {id: response.data.id.toString()}});
                        }
                    } else {
                        const response = await this.$api.parts.save(requestObject);

                        await this.uploadFiles(response.data!);
                        await this.loadData(); // Immediately reload data to show synced data
                    }

                    if (settings.REDIRECT_AFTER_SAVE) {
                        this.$router.push({name: 'parts'});
                    } else if (this.part.partSpecs.find((partSpec) => partSpec.syncReferenceId !== null)
                        && wasSyncLastSave) {
                        this.showMessage('Het onderdeel is opgeslagen. Bestanden voor gekoppelde specificaties' +
                            ' worden op de achtergrond bijgewerkt.');
                    } else {
                        this.showMessage('Het onderdeel is opgeslagen');
                    }
                } catch (e) {
                    this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                } finally {
                    this.loadingData = false;
                }
            }
        }

        public removeSpec(spec: IPartSpec) {
            if (this.part) {
                const specIdx = this.part.partSpecs.findIndex((t: any) => t === spec);
                if (specIdx >= 0) {
                    this.part.partSpecs.splice(specIdx, 1);
                }
            }
        }

        public addSpec() {
            if (this.part) {
                // create empty spec. Needed for Vue reactivity.
                const partSpec: IPartSpec = {
                    syncReferenceId: null,
                    constructionParts: [],
                    assemblyManual: undefined as any,
                    assemblyManualObject: {url: ''},
                    binModel: undefined as any,
                    binModelObject: {url: ''},
                    gltfModel: undefined as any,
                    gltfModelObject: {url: ''},
                    heightDifference: 0,
                    heights: [],
                    part: undefined,
                    price: 0,
                    svgModel: undefined as any,
                    svgModelObject: {url: ''},
                    uuid: uuid(),
                    disabled: false,
                    syncStartedAt: null,
                    syncStoppedAt: null,
                };
                this.part.partSpecs.push(partSpec);
            }
        }

        public addConstructionPart(partSpec: IPartSpec) {
            const constructionPart: IConstructionPart = {
                syncReferenceId: null,
                count: 1,
                name: '',
                dimensions: '',
                icon: undefined,
                partSpec: null as any,
                iconObject: undefined,
                syncStartedAt: null,
                syncStoppedAt: null,
                uuid: uuid(),
            };
            if (partSpec.constructionParts == null) {
                partSpec.constructionParts = [];
            }
            partSpec.constructionParts.push(constructionPart);
        }

        public removeConstructionPart(partSpec: IPartSpec, constructionPart: IConstructionPart) {
            const constructionPartIdx = partSpec.constructionParts.findIndex((t: any) => t === constructionPart);
            if (constructionPartIdx >= 0) {
                partSpec.constructionParts.splice(constructionPartIdx, 1);
            }
        }

        public addColor() {
            if (this.part) {
                // create empty spec. Needed for Vue reactivity.
                const color: IPartDefaultColors = {
                    groupName: `color${this.part.partDefaultColors.length + 1}`,
                    color: '',
                    part: null as any,
                    uuid: uuid(),
                };
                this.part.partDefaultColors.push(color);
                this.colorsAutofilled = false;
            }
        }

        public removeColor(color: IPartDefaultColors) {
            if (this.part) {
                const colorIdx = this.part.partDefaultColors.findIndex((t: any) => t === color);
                if (colorIdx >= 0) {
                    this.part.partDefaultColors.splice(colorIdx, 1);
                }
                this.colorsAutofilled = this.part.partDefaultColors.length === 0 ? null : false;
            }
        }

        private async uploadFiles(responsePart: IPart) {
            if (this.part) {
                if (this.part.iconObject && this.part.iconObject.file) {
                    const formData = new FormData();
                    formData.append('file', this.part.iconObject.file, this.part.iconObject.file.name);
                    const response = await http.post(url(`/api/v1/admin/part/${responsePart.id}/icon`), formData);
                }

                for (const partSpec of this.part.partSpecs) {
                    const match = responsePart.partSpecs.find((t: IPartSpec) => (t.uuid === partSpec.uuid && !!t.uuid)
                        || (!!t.id && t.id === partSpec.id));
                    if (match) {
                        if (partSpec.svgModelObject && partSpec.svgModelObject.file) {
                            const formData = new FormData();
                            formData.append('file', partSpec.svgModelObject.file, partSpec.svgModelObject.file.name);
                            const response = await http.post(
                                url(`/api/v1/admin/part/${responsePart.id}/part-spec/${match.id}/svg`), formData);
                        }

                        if (partSpec.gltfModelObject && partSpec.gltfModelObject.file &&
                            partSpec.binModelObject && partSpec.binModelObject.file) {
                            const formData = new FormData();
                            formData.append('gltf', partSpec.gltfModelObject.file, partSpec.gltfModelObject.file.name);
                            formData.append('bin', partSpec.binModelObject.file, partSpec.binModelObject.file.name);
                            const response = await http.post(
                                url(`/api/v1/admin/part/${responsePart.id}/part-spec/${match.id}/gltf`), formData);
                        }

                        if (partSpec.assemblyManualObject && partSpec.assemblyManualObject.file) {
                            const formData = new FormData();
                            formData.append('file', partSpec.assemblyManualObject.file,
                                partSpec.assemblyManualObject.file.name);
                            const response = await http.post(
                                url(`/api/v1/admin/part/${responsePart.id}/part-spec/${match.id}/manual`), formData);
                        }

                        if (partSpec.constructionParts != null) {
                            for (const constructionPart of partSpec.constructionParts) {
                                const constructionPartMatch = match.constructionParts
                                    .find((t: IConstructionPart) => (t.uuid === constructionPart.uuid && !!t.uuid)
                                        || (!!t.id && t.id === constructionPart.id));
                                if (constructionPartMatch) {
                                    if (constructionPart.iconObject && constructionPart.iconObject.file) {
                                        const formData = new FormData();
                                        formData.append('file', constructionPart.iconObject.file,
                                            constructionPart.iconObject.file.name);
                                        const response = await http.post(
                                            url(`/api/v1/admin/part/${responsePart.id}/part-spec/${match.id}/`
                                                + `construction-part/${constructionPartMatch.id}/icon`), formData);
                                    }
                                }
                            }
                        }
                    } else {
                        this.showError('Het opslaan het van onderdeel is mislukt.');
                    }
                }
            }
        }

        private async loadListData() {
            try {
                {
                    const response = await this.$api.parts.getPartCategories();
                    if (response.success) {
                        this.partCategories = response.data!;
                    }
                }
                {
                    const response = await this.$api.parts.getAgeCategories();
                    if (response.success) {
                        this.ageCategories = response.data!;
                    }
                }
                {
                    const response = await this.$api.parts.getDifficultyCategories();
                    if (response.success) {
                        this.difficultyCategories = response.data!;
                    }
                }
                {
                    const response = await this.$api.hazards.list(new Pagination(0, -1), []);
                    if (response.success) {
                        this.hazards = response.data!.content;
                    }
                }
                {
                    const response = await this.$api.checklistItems.list(new Pagination(0, -1), []);
                    if (response.success) {
                        this.checklistItems = response.data!.content;
                    }
                }
                {
                    const response = await this.$api.parts.getHeights();
                    if (response.success) {
                        this.heights = response.data!;
                    }
                }
                {
                    const response = await this.$api.parts.getMaterials();
                    if (response.success) {
                        this.materials = response.data!;
                    }
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            }
        }

        private validate() {
            const vues = this.findDescendants(this, (item: Vue) => {
                return (item.$options.name === 'FileInput' || item.$options.name === 'ImageInput');
            });


            let result = true;
            for (const vue of vues) {
                result = (vue as any).validate() && result;
            }
            return result;
        }

        private findDescendants(item: Vue, matcher: (item: Vue) => boolean): Vue[] {
            const result: Vue[] = [];
            for (const descendant of item.$children) {
                if (matcher(descendant)) {
                    result.push(descendant);
                }
                result.push(...result, ...this.findDescendants(descendant, matcher));
            }
            return result;
        }
    }
