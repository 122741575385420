
    import {Component, Model, Vue, Prop} from 'vue-property-decorator';


    @Component({
        name: 'Dropzone',
        components: {},
    })
    export default class Dropzone extends Vue {
        @Model('input', {type: Boolean, default: false})
        public readonly value!: boolean;

        @Prop()
        public readonly hoverStyle!: any;

        private counter = 0;

        public fileDropped(e: any) {
            const droppedFiles = e.dataTransfer.files;
            if (!droppedFiles) {
                return;
            }

            this.$emit('drop', droppedFiles);
            this.counter = 0;
            this.$emit('input', false);
        }

        public onDragEnter(e: DragEvent) {
            const dropzone = this.findClosestDropZone(e.target as HTMLElement);
            if (dropzone === this.$refs.div) {
                ++this.counter;
                this.$emit('input', true);
            }
        }

        public onDragLeave(e: DragEvent) {
            const dropzone = this.findClosestDropZone(e.target as HTMLElement);
            if (dropzone === this.$refs.div) {
                this.counter = Math.max(0, --this.counter);
                if (this.counter === 0) {
                    this.$emit('input', false);
                }
            }
        }

        private findClosestDropZone(el: HTMLElement) {
            // eslint-disable-next-line
            let parent: (Node & ParentNode) | null = el;
            while (parent != null) {
                if (parent instanceof HTMLElement) {
                    if (parent.matches('.dropzone')) {
                        return parent;
                    }
                    parent = parent.parentNode;
                } else {
                    parent = null;
                }
            }

            return null;
        }
    }
