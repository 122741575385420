import {IRole} from '@/models/Role';
import {hasRole} from '@/utils/auth';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import './plugins/lodash';
import './plugins/dayjs';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import settings from '@/utils/settings';
import Api from '@/api';
import './types';


Vue.config.productionTip = false;

/* Prepare the HTTP Endpoint URL */
settings.HTTP_URL = process.env.VUE_APP_API || 'http://localhost:8080/';
settings.HTTP_URL = settings.HTTP_URL.replace(/\/+$/, '');
settings.CONFIGURATOR_URL = process.env.VUE_APP_CONFIGURATOR_URL || 'http://localhost:8081/';
settings.CONFIGURATOR_URL = settings.CONFIGURATOR_URL.replace(/\/+$/, '');

/* set the router to check if need to be logged in before each navigation */
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiredRoles = to.matched.flatMap((record) => record.meta.roles).filter(role => role);
    if (requiresAuth && !store.state.authentication.authenticated) {
        next('/login');
    } else {
        if (requiresAuth && requiredRoles && requiredRoles.length > 0) {
            const user = store.state.authentication.user;
            const isAllowed = requiredRoles.filter((role: string) => !!role)
                .reduce((acc, curr) => {
                    return acc || hasRole(user!, curr);
                }, false);
            if (!isAllowed) {
                next('/login');
            }
        }
        if (to.meta) {
            store.commit('setPublicName', to.meta.publicName);
        }
        next();
    }
});

/* intercept responses to logout user */
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) { // if the error is 401 and hasn't already been retried
            store.commit('setAuthenticated', false);
            router.push('login');
        }
    });

/* create default Api object */
const api = new Api();
Object.defineProperty(Vue.prototype, '$api', {value: api});


let app: any;
axios.get(settings.HTTP_URL + '/api/v1/me', {withCredentials: true})
    .then((response) => {
        const isAuthenticated = (response && response.data && response.data.success) || false;
        store.commit('setAuthenticated', isAuthenticated);
        if (isAuthenticated) {
            store.commit('setUser', response.data.data);

            if (response.data.data.roles && response.data.data.roles.length === 1 &&
                response.data.data.roles.find((t: IRole) => t.name === 'customer')) {
                document.location.href = settings.CONFIGURATOR_URL;
            }
        }

        if (!app) {
            app = new Vue({
                vuetify,
                router,
                store,
                api,
                render: (h) => h(App),
            });
            app.$mount('#app');
        }
    });
