
import {IRole} from '@/models/Role';
import { Component, Vue } from 'vue-property-decorator';
import {State} from 'vuex-class';

@Component({
  components: {},
})
export default class Login extends Vue {
    public username: string = '';
    public password: string = '';
    public showError: boolean = false;
    public error: string = '';

    @State((state: any) => state.authentication.authenticated)
    public isLoggedIn!: boolean;

    private usernameRules = [(value: string) => {
        return !/^[^@]+@[^@]+\.[^@]+$/.test(value) ? 'E-mailadres is ongeldig' : true;
    }];
    private passwordRules = [(value: string) => {
        return !value ? 'Wachtwoord moet ingevuld worden.' : true;
    }];

    public created() {
        if (this.isLoggedIn) {
            this.$router.push({name: 'dashboard'});
        }
    }

    public async login($event: Event) {
        if ((this.$refs.form as any).validate() === true) {
            try {
                const result = await this.$api.auth.login(this.username, this.password);
                if (result.data && result.data.roles && result.data.roles.length === 1 &&
                    result.data.roles.find((t: IRole) => t.name === 'customer')) {
                    document.location.href = 'http://tntspeeltoestellen.nl';
                }
                this.$router.push({name: 'dashboard'});
            } catch (e: any) {
                this.showError = true;
                this.error = (e && e.response && e.response.status === 401) ?
                    'Gebruikersnaam en wachtwoord zijn onbekend.' : 'Er is een fout opgetreden.';
            }
        }
    }
}
