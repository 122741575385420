export function uuid() {
    function randomDigit() {
        if (crypto && crypto.getRandomValues) {
            const rands = new Uint8Array(1);
            crypto.getRandomValues(rands);
            return (rands[0] % 16).toString(16);
        } else {
            // tslint:disable-next-line:no-bitwise
            return ((Math.random() * 16) | 0).toString(16);
        }
    }

    const crypto = window.crypto || (window as any).msCrypto;
    return 'xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx'.replace(/x/g, randomDigit);
}

export function urlBasename(value?: string) {
    if (!value) {
        return '';
    }
    return value.substring(value.lastIndexOf('/') + 1);
}
