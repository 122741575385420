
    import {Component, Prop, Watch, Vue, Model} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class SimpleDeleteDialog extends Vue {
        @Model('input', {type: Boolean}) public readonly dialog!: boolean;
        @Prop({default: ''}) public objectType!: string;
        @Prop({default: ''}) public extraText!: string;
        @Prop({default: false}) public disabled!: boolean;

        public get lowerObjectType(): string {
            return this.objectType && this.objectType.toLowerCase();
        }
    }
