
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {SearchAction} from '@/decorators/search-action';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {IPlayground, PlaygroundStatus} from '@/models/Playground';
    import {IUser} from '@/models/User';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {hasRole} from '@/utils/auth';
    import {statusText, timeAgo} from '@/utils/filters';
    import {url} from '@/utils/http';
    import {Component, Mixins, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';
    import {IPart} from '@/models/Part';
    import {PartApi} from '@/api/part';
    import {Pagination} from '@/utils/api-tools/pagination';
    import SimpleDuplicateDialog from '@/components/SimpleDuplicateDialog.vue';

    @Component({
        filters: {
            timeAgo,
            statusText,
        },
        components: {SimpleDuplicateDialog, SimpleDeleteDialog, SimpleMessageWrapper},
    })
    export default class PlaygroundList extends Mixins(ListsObjects) {
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Naam', value: 'name'},
            {text: 'Gebruiker', value: 'user.id'},
            {text: 'Status', value: 'state'},
            {text: 'Bewerkt op', value: 'updatedAt'},
            {text: '', sortable: false},
        ];

        public duplicateDialog: boolean = false;
        public duplicateId: number = 0;
        public singleSelect: boolean = false;
        public selected: any[] = [];
        public partData: IPart[] = [];
        public partProductCodeFilter: string | null = null;

        @State((state: any) => state.authentication.user)
        public currentUser!: IUser;

        public get api() {
            return this.$api.playground;
        }

        public get isAdmin() {
            return hasRole(this.currentUser, 'admin');
        }

        public async openDuplicationDialog(id: number) {
            this.duplicateDialog = true;
            this.duplicateId = id;
        }

        public async duplicate(id: number, newName: string = '') {
            const response = await this.$api.playground.duplicate(id, newName);
            this.$router.push({name: 'playgroundsEdit', params: {id: String(response.data!.id!)}});
        }

        public toggleAll() {
            if (this.selected.length) {
                this.selected = [];
            } else {
                this.selected = this.objects.slice();
            }
        }

        public async recalculatePrice() {
            const result = await this.api.updateQuotationPrice(Array.from(this.selected.values()).map((x) => x.object.id));
            if (result.success) {
                this.showMessage('De prijzen zijn herberekend');
            } else {
                this.showError('Er is een fout opgetreden bij het herberekenen van de prijzen.');
            }
        }

        public url(path: string) {
            return url(path);
        }

        @Watch('partProductCodeFilter')
        public filterChange() {
            if (this.partProductCodeFilter) {
                this.searchCriteria = this.searchCriteria!
                    .filter((t: SearchCriterion) => t.key !== 'parts.partSpec.part.productCode');
                this.searchCriteria.push(new SearchCriterion('parts.partSpec.part.productCode',
                    `${this.partProductCodeFilter}`));
            } else {
                this.searchCriteria = this.searchCriteria!
                    .filter((t: SearchCriterion) => t.key !== 'parts.partSpec.part.productCode');
            }

            this.pagination = Object.assign({}, this.pagination, {page: 1});
        }

        @SearchAction()
        public applySearch(value: string) {
            if (value) {
                this.searchCriteria = [new SearchCriterion('*', value), new SearchCriterion('template', 'true')];
            } else {
                this.searchCriteria = [new SearchCriterion('template', 'true')];
            }

            if (this.partProductCodeFilter) {
                this.searchCriteria.push(new SearchCriterion('parts.partSpec.part.productCode',
                    `${this.partProductCodeFilter}`));
            }
            this.resetPagination();
        }

        private async created() {
            this.searchCriteria = [
                new SearchCriterion('template', 'true'),
            ];

            const partApi: PartApi = new PartApi();

            try {
                const parts = await partApi.list(new Pagination(0, -1), []);
                if (parts.data) {
                    this.partData = parts.data.content;
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het laden van de data.');
            }
        }
    }
