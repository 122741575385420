
    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {SearchAction, SearchClear} from '@/decorators/search-action';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {IRole} from '@/models/Role';
    import {SearchCriterion} from '@/utils/api-tools/search-criteria';
    import {url} from '@/utils/http';
    import {Component, Mixins, Watch} from 'vue-property-decorator';

    @Component({
        components: {SimpleDeleteDialog, SimpleMessageWrapper},
    })
    export default class UserList extends Mixins(ListsObjects) {
        public role: string = '';
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'E-mail', value: 'email'},
            {text: 'Voornaam', value: 'firstName'},
            {text: 'Achternaam', value: 'lastName'},
            {text: 'Rollen', value: 'roles', sortable: false},
            {text: '', sortable: false},
        ];

        @SearchClear()
        public searchClearFunction!: () => void;

        @Watch('role')
        public filterChange() {
            if (this.role) {
                this.searchCriteria = [
                    new SearchCriterion('roles.name', this.role),
                ];
            } else {
                this.searchCriteria = this.searchCriteria!.filter((t: SearchCriterion) => t.key !== 'roles.name');
            }
            this.resetPagination();
        }

        public get api() {
            return this.$api.user;
        }

        public displayRoles(roles: IRole[]) {
            return this.$_.map(roles, 'description').join(', ');
        }

        @SearchAction()
        public applySearch(value: string) {
            if (value) {
                this.role = '';
                this.searchCriteria = [new SearchCriterion('*', value)];
            } else {
                this.searchCriteria = this.searchCriteria!.filter((t: SearchCriterion) => t.key !== '*');
            }
            this.resetPagination();
        }

        public exportCustomers() {
            window.open(url('api/v1/admin/user/export'));
        }
    }
